import React, {Component} from 'react';
import LRH from '../helpers/LeopardReactHelper';
import {connect} from "react-redux";
import LeopardStaticUIConfig from "../foundation/LeopardStaticUIConfig";
import LDH from "../helpers/LeopardDataHelper";
import LeopardAjaxHelper from "../helpers/LeopardAjaxHelper";
import $ from "jquery";
import momenttz from "moment-timezone";
import LeopardDropdownHelper from "../helpers/LeopardDropdownHelper";
import LeopardQueryHelper from "../helpers/LeopardQueryHelper";

class LeopardReportConfiguration extends Component {
    constructor(props) {
        super(props);

        this.reportViewerInstance = null;
        this.reportDesignerInstance = null;
        this.reportLayout = null;
    }

    initializeReportWithDataset = (data, that, isLoadReportLayout, isExecuteScripts) => {
        let report = new window.Stimulsoft.Report.StiReport();
        let dataSet = new window.Stimulsoft.System.Data.DataSet("ReportDataSet");
        let dataSetTimeZone = new window.Stimulsoft.System.Data.DataSet("ReportTimeZone");
        let timezones = LeopardDropdownHelper.DropdownSelectionTimeZone;

        let defaultDS = [];
        if (!LDH.IsObjectNull(data) && !LDH.IsObjectNull(data["_defaultDS"])) {
            defaultDS = data["_defaultDS"];
        }
        dataSet.readJson({data: defaultDS});
        dataSetTimeZone.readJson({timezones});

        report.dictionary.dataStore.clear();
        report.regData(dataSet.dataSetName, "", dataSet);
        report.regData(dataSetTimeZone.dataSetName, "", dataSetTimeZone);

        let customDataSetList = [];
        if (!LDH.IsObjectNull(that.props.reportDefinition.reportCustomDataSources)) {
            let sources = that.props.reportDefinition.reportCustomDataSources;
            for (let v = 0; v < sources.length; v++) {
                let dsName = sources[v].name;
                let customDS = new window.Stimulsoft.System.Data.DataSet(dsName);

                let customData = [];
                if (!LDH.IsObjectNull(data) && !LDH.IsObjectNull(data[dsName])) {
                    customData = data[dsName];
                }

                let jsonData = {[dsName.toLowerCase()]: customData};
                customDS.readJson(jsonData);
                report.regData(dsName, "", customDS);
                customDataSetList.push({name: dsName, dataset: customDS});
            }
        }

        if (isLoadReportLayout && !LDH.IsObjectNull(that.props.reportDefinition.reportLayout) &&
            !LDH.IsValueEmpty(that.props.reportDefinition.reportLayout)) {
            report.load(that.props.reportDefinition.reportLayout);

            let timezoneName = momenttz.tz.guess();
            let varibles = report.dictionary.variables;
            if (!LDH.IsObjectNull(varibles.getByName("Input_TimezoneName")) &&
                LDH.IsValueEmpty(varibles.getByName("Input_TimezoneName").valueObject)) {
                varibles.getByName("Input_TimezoneName").valueObject = timezoneName;
            }

            if (!LDH.IsObjectNull(varibles.getByName("Input_TimezoneOffset"))) {
                let tz = momenttz().tz(timezoneName).format();
                let zone = momenttz.parseZone(tz);
                let offset = zone.utcOffset() / 60;
                varibles.getByName("Input_TimezoneOffset").valueObject = offset;
            }

            if (isExecuteScripts) {
                let jsCode = that.props.reportDefinition.clientSideUILogicForReport;
                if (!LDH.IsObjectNull(jsCode) && !LDH.IsValueEmpty(jsCode)) {
                    jsCode = LDH.FilterMacro(jsCode);
                    LRH.ExecuteClientScript(report, jsCode, "report", false);
                }
            }
        }

        return {report: report, datasets: {dataSet, dataSetTimeZone, customDataSetList}};
    };

    hideSystemInputFields = () => {
        let count = 0;
        let interval = setInterval(function () {
            $(".stiJsViewerFormButton.stiJsViewerFormButtonDefault").each(function () {
                let tdStyle = "td.stiJsViewerClearAllStyles";
                if ($(tdStyle, $(this)).length > 0 && $(tdStyle, $(this)).is(":visible") &&
                    $(tdStyle, $(this)).text().trim() === "Reset") {
                    $(this).hide();
                    $(".stiJsViewerInnerParametersPanelSimple td").each(function () {
                        if ($(this).text().trim().toLowerCase() === "input_timezoneoffset") {
                            $(this).hide();
                            $(this).next().hide();
                        }
                    });
                    if (!LDH.IsObjectNull(interval)) {
                        clearInterval(interval);
                    }
                }
            });
            if (count >= 300 && !LDH.IsObjectNull(interval)) {
                clearInterval(interval);
            }
            count++;
        }, 10);
    };

    initializeReportViewerOnExit = (data, that) => {
        let result = that.initializeReportWithDataset(data, that, true, false);
        that.reportViewerInstance.report = result.report;
        that.hideSystemInputFields();
    };

    initializeReportViewerOnInteraction = (data, that, variables) => {
        let result = that.initializeReportWithDataset(data, that, true, true);
        that.reportViewerInstance.report = result.report;
        that.hideSystemInputFields();

        let dict = result.report.dictionary;
        let keys = Object.keys(variables);

        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            let value = variables[keys[i]];
            let input = dict.variables.getByName(key);

            if (!LDH.IsObjectNull(input)) {
                input.valueObject = value;
            }
        }
    };

    initializeReportViewerOnDesign = (data, that, thisViewer) => {
        let result = that.initializeReportWithDataset(data, that, true, false);
        that.reportDesignerInstance.report = result.report;
        that.reportDesignerInstance.visible = true;
        thisViewer.visible = false;
        that.hideSystemInputFields();
    };

    initializeReportViewerOnCompDidMount = (data, that) => {
        if (!LDH.IsObjectNull(that.props.reportDefinition) &&
            !LDH.IsObjectNull(that.props.reportDefinition.reportLayout) &&
            !LDH.IsObjectNull(that.props.reportDefinition.reportLayout.ReportName)) {
            that.reportLayout = that.props.reportDefinition.reportLayout;
        }
        let result = that.initializeReportWithDataset(data, that, true, false);
        that.reportViewerInstance.report = result.report;
        that.hideSystemInputFields();
    };

    stiProgress = () => {
        try {
            let progressContainer = document.createElement("div");
            progressContainer.className = "leopard-report-loading-spinner";

            let progress = document.createElement("div");
            progressContainer.appendChild(progress);
            progress.className = "mobile_designer_loader";
            return progressContainer;
        } catch (ex) {
            console.log("Unhandled exception: ", ex);
        }
    };

    showProgress = (containerName) => {
        try {
            this.hideProgress();
            let rightPanel = document.getElementById(containerName);
            let progress = this.stiProgress();

            if (!LDH.IsObjectNull(rightPanel)) {
                rightPanel.appendChild(progress);
                rightPanel.progress = progress;
            }
            $(".leopard-report-loading-spinner-built-in").hide();
        } catch (ex) {
            console.log("Unhandled exception: ", ex);
        }
    };

    hideProgress = (containerName) => {
        try {
            let rightPanel = document.getElementById(containerName);
            if (!LDH.IsObjectNull(rightPanel) &&
                !LDH.IsObjectNull(rightPanel.progress) &&
                rightPanel.progress) {
                rightPanel.removeChild(rightPanel.progress);
                rightPanel.progress = null;
            }
            $(".js_viewer_loader").each(function () {
                if ($(this).parent().hasClass("leopard-report-loading-spinner")) {
                    return;
                }
                $(this).parent().addClass("leopard-report-loading-spinner-built-in");
            });
        } catch (ex) {
            console.log("Unhandled exception: ", ex);
        }
    };

    createReportDesigner() {
        let that = this;
        let options = new window.Stimulsoft.Designer.StiDesignerOptions();
        options.appearance.showTooltipsHelp = LeopardStaticUIConfig.ReportViewerAppearanceShowTooltipsHelp;
        options.appearance.fullScreenMode = true;
        options.toolbar.showAboutButton = LeopardStaticUIConfig.ReportViewerToolbarShowAboutButton;
        options.toolbar.showFileMenuAbout = LeopardStaticUIConfig.ReportViewerToolbarShowFileMenuAbout;
        options.toolbar.showFileMenuHelp = LeopardStaticUIConfig.ReportViewerToolbarShowFileMenuHelp;
        options.toolbar.showFileMenuInfo = LeopardStaticUIConfig.ReportViewerToolbarShowFileMenuInfo;
        options.toolbar.showFileMenuExit = LeopardStaticUIConfig.ReportViewerToolbarShowFileMenuExit;

        this.reportDesignerInstance = new window.Stimulsoft.Designer.StiDesigner(
            options, "LeopardReportDesigner", false);

        this.reportDesignerInstance.onExit = function () {
            this.visible = false;
            that.reportViewerInstance.visible = true;
            that.initializeReportViewerOnExit(null, that);
        };

        this.reportDesignerInstance.onSaveReport = function (args) {
            let jsonReport = args.report.saveToJsonString();
            let dataViewId = that.props.dataViewId;
            let userProfile = window.userProfile;
            let userId = LDH.GetUserIdFromUserProfile(userProfile);
            let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);
            let reportDef = that.props.reportDefinition;

            reportDef.reportLayout = JSON.parse(jsonReport);
            that.reportLayout = jsonReport;

            LeopardAjaxHelper.UpdateDataViewDefinition(userId, organizationId, dataViewId, reportDef, function () {
                let settingsVersionOnClient = parseInt($(".dataview-settings-version").attr("settingsversion"));
                $(".dataview-settings-version").attr("settingsversion", settingsVersionOnClient + 1);

                LRH.ShowToast("Your report has been successfully updated.", "success", 5000);
            }, function (error, sessionTimeout) {
                if (error === "version-out-of-date") {
                    LRH.ShowStaticToast("Configuration outdated", "Your current configuration settings " +
                        "cannot be saved due to a newer version found on the server, please refresh your " +
                        "browser. ", "error", true);
                } else if (sessionTimeout !== undefined && sessionTimeout === true) {
                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                } else {
                    LRH.ShowToast("Failed to update your report. " +
                        "Please check your network connection status and try again.", "error", 5000);
                }
                that.setState({showLoadingProgress: false});
            });
        };

        this.reportDesignerInstance.renderHtml("reportDesignerContent");
        LRH.TriggerWindowResizeEvent();
    }

    getSanitizedParameters = (args, keys, parameters, updateVariables) => {
        let daylightSaving = 0;
        let timezone = null;
        let timezoneName = "";
        for (let i = 0; i < keys.length; i++) {
            if (keys[i].toLowerCase() === "input_timezone") {
                timezone = args.variables[keys[i]];
            }
            if (keys[i].toLowerCase() === "input_daylightsaving") {
                daylightSaving = args.variables[keys[i]];
            }
            if (keys[i].toLowerCase() === "input_timezonename") {
                timezoneName = args.variables[keys[i]];
            }
        }

        for (let i = 0; i < keys.length; i++) {
            if (keys[i].toLowerCase() === "input_timezoneoffset" &&
                !LDH.IsValueEmpty(timezoneName)) {
                let tz = momenttz().tz(timezoneName).format();
                let zone = momenttz.parseZone(tz);
                let offset = zone.utcOffset() / 60;

                if (updateVariables) {
                    args.variables[keys[i]] = offset;
                }
            }
        }

        window["cc_daylight_saving"] = daylightSaving;
        window["cc_timezone"] = timezone;
        window["cc_timezone_name"] = timezoneName;

        for (let i = 0; i < keys.length; i++) {
            let replaceData = "";
            parameters = LDH.ReplaceAll(parameters, "{" + keys[i] + "}", args.variables[keys[i]]);
            replaceData = "{" + keys[i] + ".ToString(\"yyyy-MM-dd-startdate\")}";

            if (parameters.indexOf(replaceData) > -1) {
                let dateVal = new Date(args.variables[keys[i]]);

                if (LDH.IsValueEmpty(timezone) && LDH.IsValueEmpty(timezoneName)) {
                    let dateStr = LDH.ConvertDateToYYYYMMDD(dateVal);
                    dateStr = dateStr + "T00:00:00.000";
                    dateStr = LDH.ConvertLocalDateToUTCDate(new Date(dateStr));
                    dateStr = LDH.GetLocalISODateString(dateStr);
                    parameters = LDH.ReplaceAll(parameters, replaceData, dateStr);
                } else if (LDH.IsValueEmpty(timezone) && !LDH.IsValueEmpty(timezoneName)) {
                    let dateLocaleStr = LDH.ConvertDateToYYYYMMDD(dateVal);
                    let usDateStr = dateLocaleStr.split("-");
                    let dateParse = new Date(usDateStr[0], usDateStr[1] - 1, usDateStr[2], 0, 0, 0, 0);

                    let tz = momenttz().tz(timezoneName).format();
                    let zone = momenttz.parseZone(tz);
                    let offset = zone.utcOffset();
                    let minutes = dateParse.getMinutes();
                    dateParse.setMinutes(minutes - offset);

                    let dateStr = LDH.GetLocalISODateString(dateParse);
                    parameters = LDH.ReplaceAll(parameters, replaceData, dateStr);
                } else if (!LDH.IsValueEmpty(timezone) && LDH.IsValueEmpty(timezoneName)) {
                    let dateLocaleStr = LDH.ConvertDateToYYYYMMDD(dateVal);
                    let usDateStr = dateLocaleStr.split("-");
                    let dateParse = new Date(usDateStr[0], usDateStr[1] - 1, usDateStr[2], 0, 0, 0, 0);
                    let offset = parseFloat(timezone) + parseFloat(daylightSaving);
                    let hours = dateParse.getHours();
                    dateParse.setHours(hours - offset);

                    let dateStr = LDH.GetLocalISODateString(dateParse);
                    parameters = LDH.ReplaceAll(parameters, replaceData, dateStr);
                }
            }

            replaceData = "{" + keys[i] + ".ToString(\"yyyy-MM-dd-enddate\")}";
            if (parameters.indexOf(replaceData) > -1) {
                let dateVal = new Date(args.variables[keys[i]]);

                if (LDH.IsValueEmpty(timezone) && LDH.IsValueEmpty(timezoneName)) {
                    let dateStr = LDH.ConvertDateToYYYYMMDD(dateVal);
                    dateStr = dateStr + "T23:59:59.999";
                    dateStr = LDH.ConvertLocalDateToUTCDate(new Date(dateStr));
                    dateStr = LDH.GetLocalISODateString(dateStr);
                    parameters = LDH.ReplaceAll(parameters, replaceData, dateStr);
                } else if (LDH.IsValueEmpty(timezone) && !LDH.IsValueEmpty(timezoneName)) {
                    let dateLocaleStr = LDH.ConvertDateToYYYYMMDD(dateVal);
                    let usDateStr = dateLocaleStr.split("-");
                    let dateParse = new Date(usDateStr[0], usDateStr[1] - 1, usDateStr[2], 23, 59, 59, 999);

                    let tz = momenttz().tz(timezoneName).format();
                    let zone = momenttz.parseZone(tz);
                    let offset = zone.utcOffset();
                    let minutes = dateParse.getMinutes();
                    dateParse.setMinutes(minutes - offset);

                    let dateStr = LDH.GetLocalISODateString(dateParse);
                    parameters = LDH.ReplaceAll(parameters, replaceData, dateStr);
                } else if (!LDH.IsValueEmpty(timezone) && LDH.IsValueEmpty(timezoneName)) {
                    let dateLocaleStr = LDH.ConvertDateToYYYYMMDD(dateVal);
                    let usDateStr = dateLocaleStr.split("-");
                    let dateParse = new Date(usDateStr[0], usDateStr[1] - 1, usDateStr[2], 23, 59, 59, 999);
                    let offset = parseFloat(timezone) + parseFloat(daylightSaving);
                    let hours = dateParse.getHours();
                    dateParse.setHours(hours - offset);

                    let dateStr = LDH.GetLocalISODateString(dateParse);
                    parameters = LDH.ReplaceAll(parameters, replaceData, dateStr);
                }
            }
        }
        return parameters;
    }

    createReportViewer() {
        let that = this;
        let userProfile = window.userProfile;
        let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);

        let options = new window.Stimulsoft.Viewer.StiViewerOptions();
        options.appearance.interfaceType = window.Stimulsoft.Viewer.StiInterfaceType.Mouse;
        options.appearance.showTooltipsHelp = LeopardStaticUIConfig.ReportDesignerAppearanceShowTooltipsHelp;

        if (this.props.state.permissions.AllowReportDesigner) {
            options.toolbar.showDesignButton = LeopardStaticUIConfig.ReportDesignerToolbarShowDesignButton;
        }
        options.toolbar.showAboutButton = LeopardStaticUIConfig.ReportDesignerToolbarShowAboutButton;
        options.toolbar.showOpenButton = LeopardStaticUIConfig.ReportDesignerToolbarShowOpenButton;

        options.exports.showExportToText = false;
        options.exports.showExportToPowerPoint = false;
        options.exports.showExportToOpenDocumentCalc = false;
        options.exports.showExportToOpenDocumentWriter = false;
        options.exports.showExportToJson = false;
        options.exports.showExportToCsv = false;
        options.exports.showExportToWord2007 = false;
        options.exports.showExportToDocument = false;

        this.reportViewerInstance = new window.Stimulsoft.Viewer.StiViewer(
            options, "LeopardReportViewer", false);

        this.reportViewerInstance.onInteraction = function (args) {
            if (args.action.toLowerCase() === "initvars") {
                let dict = args.report.dictionary;
                for (let i = 0; i < args.report.variables.keys.length; i++) {
                    let key = args.report.variables.keys[i];
                    let input = dict.variables.getByName(key);

                    if (!LDH.IsObjectNull(input)) {
                        let newValue = LDH.FilterMacro(input.val, null, null);
                        input.valueObject = newValue;

                        if (!LDH.IsObjectNull(input.description) &&
                            !LDH.IsValueEmpty(input.description)) {
                            newValue = LDH.FilterMacro(input.description, null, null);
                            input.valueObject = newValue;
                        }
                    }
                }

                window["report_obj_" + that.props.dataViewId] = args.report;
                let accessibleData = [];
                for (let i = 0; i < args.report.variables.keys.length; i++) {
                    let key = args.report.variables.keys[i];
                    let input = dict.variables.getByName(key);

                    if (!LDH.IsObjectNull(input)) {
                        accessibleData.push({name: key, value: input.valueObject});
                    }
                }

                if (!LDH.IsObjectNull(that.props.reportDefinition.inputValuesInitLogic) &&
                    !LDH.IsValueEmpty(that.props.reportDefinition.inputValuesInitLogic)) {
                    let javascript = that.props.reportDefinition.inputValuesInitLogic;
                    let dataName = "data";
                    let dataValue = accessibleData;
                    let dataResult = LDH.EvaluateJavaScriptForDataShaping(javascript, dataName,
                        dataValue, that.props.dataViewId, null);

                    for (let ii = 0; ii < args.report.variables.keys.length; ii++) {
                        let key = args.report.variables.keys[ii];
                        let inputCtrl = dict.variables.getByName(key);

                        for (let jj = 0; jj < dataResult.length; jj++) {
                            if (!LDH.IsObjectNull(inputCtrl) && dataResult[jj].name === key) {
                                if (typeof dataResult[jj].value === "string") {
                                    inputCtrl.valueObject = LDH.FilterMacro(dataResult[jj].value, null, null);
                                } else {
                                    inputCtrl.valueObject = dataResult[jj].value;
                                }
                            }
                        }
                    }
                }
                that.hideSystemInputFields();
            }
            if (args.action.toLowerCase() === "variables") {
                window["report_input_variables"] = args;

                let reportDef = that.props.reportDefinition;
                let parameters = LDH.IsValueEmpty(reportDef.oDataParametersForNormalReport) ?
                    "" : "?" + reportDef.oDataParametersForNormalReport;

                let parentQuery1 = reportDef.oDataParametersParent1ForNormalReport;
                let parentQuery2 = reportDef.oDataParametersParent2ForNormalReport;
                let parentQuery3 = reportDef.oDataParametersParent3ForNormalReport;

                let keys = Object.keys(args.variables);

                parameters = that.getSanitizedParameters(args, keys, parameters, true);
                parameters = LDH.ReplaceAll(parameters, "{Input_UserGroupId}", organizationId);
                parameters = LDH.FilterMacro(parameters);

                if (!LDH.IsObjectNull(parentQuery1) && !LDH.IsValueEmpty(parentQuery1)) {
                    parentQuery1 = that.getSanitizedParameters(args, keys, parentQuery1, false);
                    parentQuery1 = LDH.ReplaceAll(parentQuery1, "{Input_UserGroupId}", organizationId);
                    parentQuery1 = LDH.FilterMacro(parentQuery1);
                }

                if (!LDH.IsObjectNull(parentQuery2) && !LDH.IsValueEmpty(parentQuery2)) {
                    parentQuery2 = that.getSanitizedParameters(args, keys, parentQuery2, false);
                    parentQuery2 = LDH.ReplaceAll(parentQuery2, "{Input_UserGroupId}", organizationId);
                    parentQuery2 = LDH.FilterMacro(parentQuery2);
                }

                if (!LDH.IsObjectNull(parentQuery3) && !LDH.IsValueEmpty(parentQuery3)) {
                    parentQuery3 = that.getSanitizedParameters(args, keys, parentQuery3, false);
                    parentQuery3 = LDH.ReplaceAll(parentQuery3, "{Input_UserGroupId}", organizationId);
                    parentQuery3 = LDH.FilterMacro(parentQuery3);
                }

                if (!LDH.IsObjectNull(reportDef.scriptShapingForReport) &&
                    !LDH.IsValueEmpty(reportDef.scriptShapingForReport)) {
                    try {
                        eval(reportDef.scriptShapingForReport);
                    } catch (ex) {
                        console.log("Failed to execute scriptShapingForReport.");
                    }
                }

                let tableNameWithParams = reportDef.tableName + parameters;
                if (reportDef.tableName === "custom-query") {
                    parameters = parameters.replace("?", "");
                    tableNameWithParams = parameters;
                }

                if (!LDH.IsValueEmpty(tableNameWithParams) && tableNameWithParams.indexOf("{") > -1 &&
                    tableNameWithParams.indexOf("}") > -1) {
                    if (tableNameWithParams.indexOf("ignoreValidation=true") === 0) {
                        that.initializeReportViewerOnInteraction(null, that, args.variables);
                        return;
                    }
                }

                that.showProgress("reportViewerContent");
                let apiUrl = window.ODataAPIGatewayUrl;
                if (!LDH.IsObjectNull(reportDef.dataSourceVersion) && reportDef.dataSourceVersion === "v2") {
                    apiUrl = ""
                } else {
                    if (reportDef.tableName === "custom-query") {
                        apiUrl = window.ODataAPIGatewayUrl;
                    } else {
                        apiUrl = apiUrl + "/" + reportDef.tableName;
                    }
                }

                let postDataQuery = reportDef.clientSideQueryForReport;
                return LeopardQueryHelper.ExecuteQueriesForReport(apiUrl, parentQuery1, parentQuery2, parentQuery3, parameters, postDataQuery, [], function (data) {
                    let resultData = {};
                    let ajaxRequestObj = [];

                    if (!LDH.IsObjectNull(data) && typeof data.finalData !== "undefined") {
                        data = data.finalData;
                    }

                    resultData["_defaultDS"] = data;
                    let ajaxCount = 0;
                    let ajaxAdditionalCount = 0;

                    if (!LDH.IsObjectNull(reportDef.reportCustomDataSources)) {
                        for (let v = 0; v < reportDef.reportCustomDataSources.length; v++) {
                            let customQuery = reportDef.reportCustomDataSources[v].query;
                            let dsName = reportDef.reportCustomDataSources[v].name;
                            let postDataQuery = reportDef.reportCustomDataSources[v].postDataQuery;
                            let customParentQuery1 = reportDef.reportCustomDataSources[v].parentQuery1;
                            let customParentQuery2 = reportDef.reportCustomDataSources[v].parentQuery2;
                            let customParentQuery3 = reportDef.reportCustomDataSources[v].parentQuery3;

                            customQuery = that.getSanitizedParameters(args, keys, customQuery, false);
                            customQuery = LDH.ReplaceAll(customQuery, "{Input_UserGroupId}", organizationId);
                            customQuery = LDH.FilterMacro(customQuery);

                            if (!LDH.IsObjectNull(customParentQuery1) && !LDH.IsValueEmpty(customParentQuery1)) {
                                customParentQuery1 = that.getSanitizedParameters(args, keys, customParentQuery1, false);
                                customParentQuery1 = LDH.ReplaceAll(customParentQuery1, "{Input_UserGroupId}", organizationId);
                                customParentQuery1 = LDH.FilterMacro(customParentQuery1);
                                if (!LDH.IsValueEmpty(customParentQuery1)) ajaxAdditionalCount++;
                            }

                            if (!LDH.IsObjectNull(customParentQuery2) && !LDH.IsValueEmpty(customParentQuery2)) {
                                customParentQuery2 = that.getSanitizedParameters(args, keys, customParentQuery2, false);
                                customParentQuery2 = LDH.ReplaceAll(customParentQuery2, "{Input_UserGroupId}", organizationId);
                                customParentQuery2 = LDH.FilterMacro(customParentQuery2);
                                if (!LDH.IsValueEmpty(customParentQuery2)) ajaxAdditionalCount++;
                            }

                            if (!LDH.IsObjectNull(customParentQuery3) && !LDH.IsValueEmpty(customParentQuery3)) {
                                customParentQuery3 = that.getSanitizedParameters(args, keys, customParentQuery3, false);
                                customParentQuery3 = LDH.ReplaceAll(customParentQuery3, "{Input_UserGroupId}", organizationId);
                                customParentQuery3 = LDH.FilterMacro(customParentQuery3);
                                if (!LDH.IsValueEmpty(customParentQuery3)) ajaxAdditionalCount++;
                            }

                            if (!LDH.IsObjectNull(reportDef.reportCustomDataSources[v].queryShaping) &&
                                !LDH.IsValueEmpty(reportDef.reportCustomDataSources[v].queryShaping)) {
                                try {
                                    eval(reportDef.reportCustomDataSources[v].queryShaping);
                                } catch (ex) {
                                    console.log("Failed to execute queryShaping.");
                                }
                            }

                            if (LDH.IsValueEmpty(customQuery) === false) {
                                LeopardQueryHelper.ExecuteQueriesForReport(apiUrl, customParentQuery1, customParentQuery2, customParentQuery3,
                                    customQuery, postDataQuery, ajaxRequestObj, function (data, queryType, ajaxTotalCount) {
                                        if (!LDH.IsObjectNull(data) && typeof data.finalData !== "undefined") {
                                            data = data.finalData;
                                        }
                                        resultData[dsName] = data;
                                        ajaxCount += ajaxTotalCount;
                                    }, function () {
                                        resultData[dsName] = null;
                                        ajaxCount++;
                                    });
                            } else {
                                resultData[dsName] = [];
                                if (!LDH.IsObjectNull(postDataQuery) &&
                                    !LDH.IsValueEmpty(postDataQuery)) {
                                    var data = LRH.ExecuteClientScript([],
                                        postDataQuery, "data", true);
                                    resultData[dsName] = data;
                                }
                                ajaxCount++;
                            }
                        }
                    }

                    let interval = setInterval(function () {
                        var count = 0;
                        if (typeof reportDef.reportCustomDataSources !== "undefined") {
                            count = reportDef.reportCustomDataSources.length;
                            count += ajaxAdditionalCount;
                        }
                        if (ajaxCount >= count) {
                            if (interval !== null) clearInterval(interval);
                            Promise.all(ajaxRequestObj).then(() => {
                                that.initializeReportViewerOnInteraction(resultData, that, args.variables);
                                that.hideProgress("reportViewerContent");
                            }).catch(() => {
                                that.initializeReportViewerOnInteraction(null, that, args.variables);
                                that.hideProgress("reportViewerContent");
                            });
                        }
                    }, 100);
                }, function (data) {
                    that.initializeReportViewerOnInteraction(null, that, args.variables);
                    that.hideProgress("reportViewerContent");
                });
            }
        };

        this.reportViewerInstance.onDesignReport = function () {
            that.createReportDesigner();

            let thisViewer = this;
            that.initializeReportViewerOnDesign(null, that, thisViewer);
        };

        this.reportViewerInstance.renderHtml("reportViewerContent");
    }

    componentWillUnmount() {
        window["report_input_variables"] = null;
        window["report_obj_" + this.props.dataViewId] = null;
    }

    componentDidMount() {
        let that = this;
        let version = LDH.ReplaceAll(LeopardStaticUIConfig.ControlCentreVersion, ".", "");

        if ($("#stimulsoft-viewer-css").length === 0) {
            $("head").prepend('<link rel="stylesheet" id="stimulsoft-viewer-css" type="text/css" ' +
                'href="/css/stimulsoft/2022.2.6/stimulsoft.viewer.office2013.whiteorange.css?v=' + version + '" />');
        }
        if ($("#stimulsoft-designer-css").length === 0) {
            $("head").prepend('<link rel="stylesheet" id="stimulsoft-designer-css" type="text/css" ' +
                'href="/css/stimulsoft/2022.2.6/stimulsoft.designer.office2013.whiteorange.css?v=' + version + '" />');
        }
        if ($("#stimulsoft-reports").length === 0) {
            $("head").append('<script id="stimulsoft-reports" async ' +
                'src="/js/stimulsoft/2022.2.6/stimulsoft.reports.js?v=' + version + '"></script>');
        }
        if ($("#stimulsoft-viewer").length === 0) {
            $("head").append('<script id="stimulsoft-viewer" async ' +
                'src="/js/stimulsoft/2022.2.6/stimulsoft.viewer.js?v=' + version + '"></script>');
        }
        if ($("#stimulsoft-designer").length === 0) {
            $("head").append('<script id="stimulsoft-designer" async ' +
                'src="/js/stimulsoft/2022.2.6/stimulsoft.designer.js?v=' + version + '"></script>');
        }
        window.Stimulsoft.Base.StiLicense.key = LeopardStaticUIConfig.ReportLicenseKey;
        that.createReportViewer();
        that.initializeReportViewerOnCompDidMount(null, that);
    }

    render() {
        return (
            <React.Fragment>
                <div id={"reportViewerContent"}></div>
                <div id={"reportDesignerContent"}></div>
            </React.Fragment>
        );
    }
}

const RetrieveDataFromReducer = (state) => {
    return {state};
};

export default connect(RetrieveDataFromReducer)(LeopardReportConfiguration);
