import React, {Component} from 'react';
import Box, {Item} from 'devextreme-react/box';
import {NumberBox, SelectBox, TextArea, TextBox} from 'devextreme-react';
import LDH from '../helpers/LeopardDataHelper';
import LeopardTooltipWithIcon from "./LeopardTooltipWithIcon";
import LeopardDesignerTagBox from "./LeopardDesignerTagBox";
import LDPH from "../helpers/LeopardDropdownHelper";
import {Button as TextBoxButton} from "devextreme-react/text-box";
import {alert} from 'devextreme/ui/dialog';

class LeopardCommandLinks extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customColumnOverallWidth: 200,
            customColumns: null
        };

        this.actionItemCollection = [];
        this.uiObjectInstances = [];
    }

    componentDidMount() {
        if (LDH.IsObjectNull(this.props.customColumnConfiguration)) {
            this.actionItemCollection = [];
            this.setState({customColumns: []});
        } else {
            let customColumns = this.props.customColumnConfiguration.customColumns;
            this.actionItemCollectionUpdate(customColumns);

            let width = this.props.customColumnConfiguration.customColumnOverallWidth;
            this.setState({customColumns, customColumnOverallWidth: width});
        }
    }

    customColumnOverallWidthOnChange = (e) => {
        let that = this;
        this.setState({customColumnOverallWidth: e.value}, function () {
            this.props.columnColumnDataOnChanged(that.state);
        });
    };

    actionItemCollectionUpdate = (dataSource) => {
        this.actionItemCollection = [];
        for (let i = 0; i < dataSource.length; i++) {
            if (dataSource[i].columnType !== "action-item") {
                continue;
            }
            this.actionItemCollection.push({
                value: dataSource[i].id,
                text: dataSource[i].linkText
            });
        }
        let keys = Object.keys(this.uiObjectInstances);
        for (let j = 0; j < keys.length; j++) {
            if (keys[j].startsWith("associatedActionItems_")) {
                this.uiObjectInstances[keys[j]].option(
                    "items", this.actionItemCollection);
            }
        }
    }

    initializeAvailablePropertiesForColumn = (column) => {
        column["showVisibleCountField"] = false;
        column["showRequestURLOverrideField"] = false;
        column["showThumbnailField"] = false;
        column["showPostDataProcessingField"] = false;
        column["showAPIGatewayPathField"] = false;
        column["showEventSyncRequest"] = false;
        column["showReportDataViewId"] = false;
        column["showEditFormFields"] = false;
        column["disableLinkText"] = false;
        column["showUserRoleSelection"] = false;
        column["showDataSourceId"] = false;
        column["showTemplateDirectory"] = false;
        column["showTemplateFilename"] = false;
        column["showIteration"] = false;
        column["showEnableAdminUsersOnly"] = false;
        column["showEnableCancelButton"] = false;
        column["showEnableApplyButton"] = false;
        column["showEnableCloseButton"] = false;
        column["showCustomAccessibilityLogic"] = false;
        column["showAssociatedActionItems"] = false;
        column["showActionItemBehaviour"] = false;
        column["showHttpRequestType"] = false;
        column["showConfirmationDialog"] = false;
        column["showConfirmationDialogTitle"] = false;
        column["showConfirmationDialogDescription"] = false;
        column["showAutoRefreshTimeAfterSubmit"] = false;
        column["showQueryStringScript"] = false;
        column["showJsonDataToSend"] = false;
        column["showHttpResponseCallback"] = false;
        column["showDisplayLinkSideBySide"] = false;
    }

    updateValueForCustomColumn = (data) => {
        let that = this;
        let clonedColumns = LDH.DeepClone(this.state.customColumns);
        for (let i = 0; i < clonedColumns.length; i++) {
            let currentColumn = clonedColumns[i];
            if (currentColumn.id === data.id) {
                let pName = data.propertyName;
                if (pName === "disabledFields" || pName === "hiddenFields" ||
                    pName === "invisibleFields" || pName === "userRoles" ||
                    pName === "associatedActionItems") {
                    currentColumn[pName] = data.e.fieldValue;
                } else {
                    currentColumn[pName] = data.e.value;
                }

                if (pName === "columnType") {
                    that.initializeAvailablePropertiesForColumn(currentColumn);

                    if (data.e.value === "modify-row") {
                        currentColumn.showAPIGatewayPathField = true;
                        currentColumn.showEventSyncRequest = true;
                        currentColumn.showEditFormFields = true;
                        currentColumn.showDataSourceId = true;
                        currentColumn.showEnableAdminUsersOnly = true;
                        currentColumn.showEnableCancelButton = true;
                        currentColumn.showEnableCloseButton = true;
                        currentColumn.showEnableCloseButton = true;
                    } else if (data.e.value === "reset-password") {
                        currentColumn.showEditFormFields = true;
                        currentColumn.showDataSourceId = true;
                        currentColumn.showEnableAdminUsersOnly = true;
                        currentColumn.showEnableCancelButton = true;
                        currentColumn.showEnableCloseButton = true;
                        currentColumn.showEnableCloseButton = true;
                    } else if (data.e.value === "delete-row" ||
                        data.e.value === "disable-row") {
                        currentColumn.showAPIGatewayPathField = true;
                        currentColumn.showEventSyncRequest = true;
                        currentColumn.showDataSourceId = true;
                        currentColumn.showEnableAdminUsersOnly = true;
                        currentColumn.showEnableCancelButton = true;
                        currentColumn.showEnableCloseButton = true;
                        currentColumn.showEnableCloseButton = true;
                    } else if (data.e.value === "add-row") {
                        currentColumn.showAPIGatewayPathField = true;
                        currentColumn.showEventSyncRequest = true;
                        currentColumn.showEditFormFields = true;
                        currentColumn.linkText = "Add Row";
                        currentColumn.disableLinkText = true;
                        currentColumn.showDataSourceId = true;
                        currentColumn.showEnableAdminUsersOnly = true;
                        currentColumn.showEnableCancelButton = true;
                        currentColumn.showEnableCloseButton = true;
                        currentColumn.showEnableCloseButton = true;
                        currentColumn.showTemplateDirectory = true;
                        currentColumn.showTemplateFilename = true;
                    } else if (data.e.value === "action-group") {
                        currentColumn.linkText = "Actions";
                        currentColumn.showUserRoleSelection = true;
                        currentColumn.showCustomAccessibilityLogic = true;
                        currentColumn.showAssociatedActionItems = true;
                        currentColumn.showEnableAdminUsersOnly = true;
                    } else if (data.e.value === "action-item") {
                        currentColumn.showUserRoleSelection = true;
                        currentColumn.showCustomAccessibilityLogic = true;
                        currentColumn.showActionItemBehaviour = true;
                        currentColumn.showDisplayLinkSideBySide = true;
                        currentColumn.showEnableAdminUsersOnly = true;
                    } else if (data.e.value === "photo-gallery") {
                        currentColumn.showVisibleCountField = true;
                        currentColumn.showRequestURLOverrideField = true;
                        currentColumn.showThumbnailField = true;
                        currentColumn.showPostDataProcessingField = true;
                        currentColumn.showDataSourceId = true;
                        currentColumn.showEnableAdminUsersOnly = true;
                        currentColumn.showEnableCancelButton = true;
                        currentColumn.showEnableCloseButton = true;
                        currentColumn.showEnableCloseButton = true;
                    } else if (data.e.value === "pdf-viewer") {
                        currentColumn.showEnableAdminUsersOnly = true;
                        currentColumn.showEnableCancelButton = true;
                        currentColumn.showEnableCloseButton = true;
                        currentColumn.showEnableCloseButton = true;
                        currentColumn.showReportDataViewId = true;
                    }
                }

                if ((pName === "actionItemBehaviour" && data.item.columnType === "action-item") ||
                    (pName === "columnType" && data.e.value === "action-item")) {
                    currentColumn.showAPIGatewayPathField = false;
                    currentColumn.showEventSyncRequest = false;
                    currentColumn.showReportDataViewId = false;
                    currentColumn.showEditFormFields = false;
                    currentColumn.showDataSourceId = false;
                    currentColumn.showHttpRequestType = false;
                    currentColumn.showQueryStringScript = false;
                    currentColumn.showJsonDataToSend = false;
                    currentColumn.showHttpResponseCallback = false;
                    currentColumn.showConfirmationDialog = false;
                    currentColumn.showConfirmationDialogTitle = false;
                    currentColumn.showConfirmationDialogDescription = false;
                    currentColumn.showAutoRefreshTimeAfterSubmit = false;
                    currentColumn.showIteration = false;
                    currentColumn.showTemplateDirectory = false;
                    currentColumn.showTemplateFilename = false;

                    if ((pName === "actionItemBehaviour" && data.item.columnType === "action-item" &&
                            data.e.value === "popup-editor") ||
                        (pName === "columnType" && data.e.value === "action-item" &&
                            data.item.actionItemBehaviour === "popup-editor")) {
                        currentColumn.showAPIGatewayPathField = true;
                        currentColumn.showEventSyncRequest = true;
                        currentColumn.showEditFormFields = true;
                        currentColumn.showDataSourceId = true;
                    }

                    if ((pName === "actionItemBehaviour" && data.item.columnType === "action-item" &&
                            data.e.value === "send-request") ||
                        (pName === "columnType" && data.e.value === "action-item" &&
                            data.item.actionItemBehaviour === "send-request")) {
                        currentColumn.showAPIGatewayPathField = true;
                        currentColumn.showEventSyncRequest = true;
                        currentColumn.showHttpRequestType = true;
                        currentColumn.showQueryStringScript = true;
                        currentColumn.showJsonDataToSend = true;
                        currentColumn.showHttpResponseCallback = true;
                        currentColumn.showConfirmationDialog = true;
                        currentColumn.showConfirmationDialogTitle = true;
                        currentColumn.showConfirmationDialogDescription = true;
                        currentColumn.showAutoRefreshTimeAfterSubmit = true;
                    }
                }
            }
        }
        this.actionItemCollectionUpdate(clonedColumns);

        this.setState({
            customColumns: clonedColumns
        }, function () {
            this.props.columnColumnDataOnChanged(that.state);
        });
    };

    customColumnDeleteOnClick = (data) => {
        let that = this;
        let clonedColumns = LDH.DeepClone(this.state.customColumns);
        let filteredColumns = [];
        for (let i = 0; i < clonedColumns.length; i++) {
            if (clonedColumns[i].id !== data.id) {
                filteredColumns.push(clonedColumns[i]);
            }
        }
        this.actionItemCollectionUpdate(filteredColumns);
        this.setState({customColumns: filteredColumns}, function () {
            this.props.columnColumnDataOnChanged(that.state);
        });
    };

    generalAddColumnOnClick = () => {
        let that = this;
        let customColumn = {
            id: LDH.GenerateGuid(),
            columnType: LDPH.DropdownSelectionColumnTypeSource[0].value,
            linkText: "",
            dataSourceId: "",
            confirmationDialogVisibility: LDPH.DropdownSelectionYesNo[0].id
        };
        this.initializeAvailablePropertiesForColumn(customColumn);
        customColumn.showVisibleCountField = true;
        customColumn.showRequestURLOverrideField = true;
        customColumn.showThumbnailField = true;
        customColumn.showPostDataProcessingField = true;
        customColumn.enableAdminUserOnly = true;
        customColumn.enableCancelButton = true;
        customColumn.enableApplyButton = true;
        customColumn.enableCloseButton = false;

        customColumn.showDataSourceId = true;
        customColumn.showEnableCancelButton = true;
        customColumn.showEnableApplyButton = true;
        customColumn.showEnableCloseButton = true;
        customColumn.showEnableAdminUsersOnly = true;

        this.setState({
            customColumns: [...this.state.customColumns, customColumn]
        }, function () {
            this.props.columnColumnDataOnChanged(that.state);
        });
    };

    addUIObjectInstance = (data) => {
        if (LDH.IsObjectNull(data.ref) || LDH.IsObjectNull(data.ref.ref) ||
            LDH.IsObjectNull(data.ref.ref.instance)) {
            return;
        }
        this.uiObjectInstances[data.uiObjectName] = data.ref.ref.instance;
    };

    leopardDesignerTagBoxOnOpened = (data) => {
        if (LDH.IsObjectNull(data) || LDH.IsObjectNull(data.component)) {
            return;
        }
        data.component.option("items", this.actionItemCollection);
    };

    leopardDesignerTagBoxOnClosed = (data) => {
        if (LDH.IsObjectNull(data) || LDH.IsObjectNull(data.component)) {
            return;
        }
        data.component.option("items", this.actionItemCollection);
    };

    render() {
        let that = this;
        if (this.state.customColumns === null) return null;

        let result = this.state.customColumns.map(function (item, i) {
            return (
                <Item key={i} baseSize={300}>
                    <div className="leopard-settings-panel-cell">
                        <div className="leopard-settings-panel-title" style={{float: "left", width: "243px"}}>
                            <span>{LDH.IsValueEmpty(that.state.customColumns[i].linkText)
                                ? "Command Link " + (i + 1)
                                : that.state.customColumns[i].linkText}
                            </span>
                        </div>
                        <div style={{float: "right", width: "22px", textAlign: "center", marginRight: "10px"}}>
                            <i className="leopard-configurepanel-title-button remove fas fa-minus"
                               onClick={(e) => that.customColumnDeleteOnClick({
                                   e, id: that.state.customColumns[i].id
                               })}></i>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"customColumnType_" + that.state.customColumns[i].id + "_help"}
                                    title={"Type of link"}
                                    text={"Specify a type of link for the data view."}/>
                                <span>Type of link:</span>
                            </div>
                            <div>
                                <SelectBox dataSource={LDPH.DropdownSelectionColumnTypeSource} displayExpr={'text'}
                                           valueExpr={'value'}
                                           value={that.state.customColumns[i].columnType}
                                           onValueChanged={(e) => that.updateValueForCustomColumn({
                                               e, id: that.state.customColumns[i].id,
                                               propertyName: "columnType", item: item
                                           })}/>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon elementId={"customColumnLinkText_" + i + "_help"}
                                                        title={"Link text"}
                                                        text={"Specify a display text for the link."}/>
                                <span>Link text:</span>
                            </div>
                            <div>
                                <TextBox value={that.state.customColumns[i].linkText} maxLength={20}
                                         onValueChanged={(e) => that.updateValueForCustomColumn({
                                             e, id: that.state.customColumns[i].id,
                                             propertyName: "linkText", item: item
                                         })}
                                         disabled={
                                             !LDH.IsObjectNull(that.state.customColumns[i].disableLinkText) &&
                                             that.state.customColumns[i].disableLinkText ? true : false
                                         }/>
                            </div>
                        </div>
                        {
                            LDH.IsObjectNull(that.state.customColumns[i].showDisplayLinkSideBySide) ||
                            that.state.customColumns[i].showDisplayLinkSideBySide === false ? "" :
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon
                                            elementId={"customColumnDisplayLinkSideBySide_" + that.state.customColumns[i].id + "_help"}
                                            title={"Display the link side by side"}
                                            text={"Show the link side by side on each row of the grid view."}/>
                                        <span>Display the link side by side:</span>
                                    </div>
                                    <div>
                                        <SelectBox dataSource={LDPH.DropdownSelectionYesNo}
                                                   displayExpr={'name'} valueExpr={'id'}
                                                   value={that.state.customColumns[i].isDisplaySideBySide}
                                                   onValueChanged={(e) => that.updateValueForCustomColumn({
                                                       e, id: that.state.customColumns[i].id,
                                                       propertyName: "isDisplaySideBySide", item: item
                                                   })}/>
                                    </div>
                                </div>
                        }
                        {
                            LDH.IsObjectNull(that.state.customColumns[i].showUserRoleSelection) ||
                            that.state.customColumns[i].showUserRoleSelection === false ? "" :
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon
                                            elementId={"customColumnUserRoleSelection_" + i + "_help"}
                                            title={"Visibility by user roles"}
                                            text={"Set the accessibility of this feature by user roles."}/>
                                        <span>Visibility by user roles:</span>
                                    </div>
                                    <div>
                                        <LeopardDesignerTagBox dropdownList={window.userRolesLookup}
                                                               fieldValue={that.state.customColumns[i].userRoles}
                                                               fieldType={"commandlink-user-roles"} uiRef={null}
                                                               leopardDesignerTagBoxValueOnChange={
                                                                   (e) => that.updateValueForCustomColumn({
                                                                       e, id: that.state.customColumns[i].id,
                                                                       propertyName: "userRoles", item: item
                                                                   })
                                                               }/>
                                    </div>
                                </div>
                        }
                        {
                            LDH.IsObjectNull(that.state.customColumns[i].showCustomAccessibilityLogic) ||
                            that.state.customColumns[i].showCustomAccessibilityLogic === false ? "" :
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon
                                            elementId={"customColumnCustomAccessibilityLogic_" + i + "_help"}
                                            title={"Accessibility logic"}
                                            text={"Write a JavaScript code to determine the accessibility of this link."}/>
                                        <span>Accessibility logic:</span>
                                    </div>
                                    <div>
                                        <TextArea value={that.state.customColumns[i].customAccessibilityLogic}
                                                  height={100}
                                                  placeholder={"Use the following variables to get started: rowData, userRoles, userProfile. Must return one of the values: 'hidden', 'disabled', 'visible'."}
                                                  onValueChanged={(e) => that.updateValueForCustomColumn({
                                                      e, id: that.state.customColumns[i].id,
                                                      propertyName: "customAccessibilityLogic", item: item
                                                  })}>
                                        </TextArea>
                                    </div>
                                </div>
                        }
                        {
                            LDH.IsObjectNull(that.state.customColumns[i].showAssociatedActionItems) ||
                            that.state.customColumns[i].showAssociatedActionItems === false ? "" :
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon
                                            elementId={"customColumnAssociatedActionItems_" + i + "_help"}
                                            title={"Associated action items"}
                                            text={"Add or remove Action Items from/to this Action Group."}/>
                                        <span>Associated action items:</span>
                                    </div>
                                    <div>
                                        <LeopardDesignerTagBox
                                            dropdownList={that.actionItemCollection}
                                            fieldValue={that.state.customColumns[i].associatedActionItems}
                                            fieldType={"commandlink-action-items"}
                                            addUIObjectInstance={(e) => that.addUIObjectInstance({
                                                uiObjectName: "associatedActionItems_" + i + "_TagBox", ref: e
                                            })}
                                            leopardDesignerTagBoxOnOpened={
                                                (e) => that.leopardDesignerTagBoxOnOpened(e)
                                            }
                                            leopardDesignerTagBoxOnClosed={
                                                (e) => that.leopardDesignerTagBoxOnClosed(e)
                                            }
                                            leopardDesignerTagBoxValueOnChange={
                                                (e) => that.updateValueForCustomColumn({
                                                    e, id: that.state.customColumns[i].id,
                                                    propertyName: "associatedActionItems", item: item
                                                })
                                            }/>
                                    </div>
                                </div>
                        }
                        {
                            LDH.IsObjectNull(that.state.customColumns[i].showActionItemBehaviour) ||
                            that.state.customColumns[i].showActionItemBehaviour === false ? "" :
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon
                                            elementId={"customColumnActionItemActionBehaviour_" + that.state.customColumns[i].id + "_help"}
                                            title={"Action behaviour"}
                                            text={"Specify an action behaviour for the Action Item."}/>
                                        <span>Action behaviour:</span>
                                    </div>
                                    <div>
                                        <SelectBox dataSource={LDPH.DropdownSelectionActionItemBehaviour}
                                                   displayExpr={'name'} valueExpr={'id'}
                                                   value={that.state.customColumns[i].actionItemBehaviour}
                                                   onValueChanged={(e) => that.updateValueForCustomColumn({
                                                       e, id: that.state.customColumns[i].id,
                                                       propertyName: "actionItemBehaviour", item: item
                                                   })}/>
                                    </div>
                                </div>
                        }
                        {
                            !LDH.IsObjectNull(that.state.customColumns[i].showEnableAdminUsersOnly) &&
                            that.state.customColumns[i].showEnableAdminUsersOnly === false ? "" :
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon
                                            elementId={"customColumnAvailableForAdminOnly_" + i + "_help"}
                                            title={"Enable for admin users only"}
                                            text={"Specify whether this feature is for admin users only."}/>
                                        <span>Enable for admin users only:</span>
                                    </div>
                                    <div>
                                        <SelectBox dataSource={LDPH.DropdownSelectionYesNo} displayExpr={'name'}
                                                   valueExpr={'id'}
                                                   value={that.state.customColumns[i].enableAdminUserOnly}
                                                   onValueChanged={(e) => that.updateValueForCustomColumn({
                                                       e, id: that.state.customColumns[i].id,
                                                       propertyName: "enableAdminUserOnly", item: item
                                                   })}/>
                                    </div>
                                </div>
                        }
                        {
                            !LDH.IsObjectNull(that.state.customColumns[i].showEnableCancelButton) &&
                            that.state.customColumns[i].showEnableCancelButton === false ? "" :
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon
                                            elementId={"customColumnEnableCancelButton_" + i + "_help"}
                                            title={"Show cancel button"}
                                            text={"Specify whether to show the cancel button on the popup form."}/>
                                        <span>Show cancel button:</span>
                                    </div>
                                    <div>
                                        <SelectBox dataSource={LDPH.DropdownSelectionYesNo} displayExpr={'name'}
                                                   valueExpr={'id'}
                                                   value={that.state.customColumns[i].enableCancelButton}
                                                   onValueChanged={(e) => that.updateValueForCustomColumn({
                                                       e, id: that.state.customColumns[i].id,
                                                       propertyName: "enableCancelButton", item: item
                                                   })}/>
                                    </div>
                                </div>
                        }
                        {
                            !LDH.IsObjectNull(that.state.customColumns[i].showEnableApplyButton) &&
                            that.state.customColumns[i].showEnableApplyButton === false ? "" :
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon
                                            elementId={"customColumnEnableApplyButton_" + i + "_help"}
                                            title={"Show apply button"}
                                            text={"Specify whether to show the apply button on the popup form."}/>
                                        <span>Show apply button:</span>
                                    </div>
                                    <div>
                                        <SelectBox dataSource={LDPH.DropdownSelectionYesNo} displayExpr={'name'}
                                                   valueExpr={'id'}
                                                   value={that.state.customColumns[i].enableApplyButton}
                                                   onValueChanged={(e) => that.updateValueForCustomColumn({
                                                       e, id: that.state.customColumns[i].id,
                                                       propertyName: "enableApplyButton", item: item
                                                   })}/>
                                    </div>
                                </div>
                        }
                        {
                            !LDH.IsObjectNull(that.state.customColumns[i].showEnableCloseButton) &&
                            that.state.customColumns[i].showEnableCloseButton === false ? "" :
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon
                                            elementId={"customColumnEnableCloseButton_" + i + "_help"}
                                            title={"Show close button"}
                                            text={"Specify whether to show the close button on the popup form."}/>
                                        <span>Show close button:</span>
                                    </div>
                                    <div>
                                        <SelectBox dataSource={LDPH.DropdownSelectionYesNo} displayExpr={'name'}
                                                   valueExpr={'id'}
                                                   value={that.state.customColumns[i].enableCloseButton}
                                                   onValueChanged={(e) => that.updateValueForCustomColumn({
                                                       e, id: that.state.customColumns[i].id,
                                                       propertyName: "enableCloseButton", item: item
                                                   })}/>
                                    </div>
                                </div>
                        }
                        {
                            !LDH.IsObjectNull(that.state.customColumns[i].showReportDataViewId) &&
                            that.state.customColumns[i].showReportDataViewId === false ? "" :
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon
                                            elementId={"customColumnReportDataViewId_" + i + "_help"}
                                            title={"Report dataview ID"}
                                            text={"Specify a Report dataview ID for this link."}/>
                                        <span>Report dataview ID:</span>
                                    </div>
                                    <div>
                                        <SelectBox dataSource={that.props.reportDataSourceForDropdown}
                                                   displayExpr={'text'}
                                                   valueExpr={'value'}
                                                   value={that.state.customColumns[i].reportDataViewId}
                                                   onValueChanged={(e) => that.updateValueForCustomColumn({
                                                       e, id: that.state.customColumns[i].id,
                                                       propertyName: "reportDataViewId", item: item
                                                   })}/>
                                    </div>
                                </div>
                        }
                        {
                            !LDH.IsObjectNull(that.state.customColumns[i].showAPIGatewayPathField) &&
                            !that.state.customColumns[i].showAPIGatewayPathField ? "" :
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon elementId={"customColumnAPIGatewayPath_" + i + "_help"}
                                                                title={"API gateway path"}
                                                                text={"Specify an API gateway path for this custom column."}/>
                                        <span>API gateway path:</span>
                                    </div>
                                    <div>
                                        <TextBox value={that.state.customColumns[i].apiGatewayPath}
                                                 placeholder={"/users"}
                                                 onValueChanged={(e) => that.updateValueForCustomColumn({
                                                     e,
                                                     id: that.state.customColumns[i].id,
                                                     propertyName: "apiGatewayPath", item: item
                                                 })}/>
                                    </div>
                                </div>
                        }
                        {
                            !LDH.IsObjectNull(that.state.customColumns[i].showEventSyncRequest) &&
                            !that.state.customColumns[i].showEventSyncRequest ? "" :
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon
                                            elementId={"customColumnEventSyncRequest_" + i + "_help"}
                                            title={"EventSync request"}
                                            text={"Specify an EventSync request path for this custom column."}/>
                                        <span>EventSync request for data retrieval:</span>
                                    </div>
                                    <div>
                                        <TextArea value={that.state.customColumns[i].eventSyncRequest}
                                                  height={100}
                                                  onValueChanged={(e) => that.updateValueForCustomColumn({
                                                      e, id: that.state.customColumns[i].id,
                                                      propertyName: "eventSyncRequest", item: item
                                                  })}>
                                        </TextArea>
                                    </div>
                                </div>
                        }
                        {
                            LDH.IsObjectNull(that.state.customColumns[i].showConfirmationDialog) ||
                            that.state.customColumns[i].showConfirmationDialog === false ? "" :
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon
                                            elementId={"customColumnShowConfirmationDialog_" + i + "_help"}
                                            title={"Show confirmation dialog"}
                                            text={"Show a confirmation dialog before sending data to the backend."}/>
                                        <span>Show confirmation dialog:</span>
                                    </div>
                                    <div>
                                        <SelectBox dataSource={LDPH.DropdownSelectionYesNo}
                                                   displayExpr={'name'}
                                                   valueExpr={'id'}
                                                   value={that.state.customColumns[i].confirmationDialogVisibility}
                                                   onValueChanged={(e) => that.updateValueForCustomColumn({
                                                       e, id: that.state.customColumns[i].id,
                                                       propertyName: "confirmationDialogVisibility", item: item
                                                   })}/>
                                    </div>
                                </div>
                        }
                        {
                            LDH.IsObjectNull(that.state.customColumns[i].showConfirmationDialogTitle) ||
                            that.state.customColumns[i].showConfirmationDialogTitle === false ? "" :
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon
                                            elementId={"customColumnConfirmationDialogTitle_" + i + "_help"}
                                            title={"Confirmation dialog title"}
                                            text={"Specify a title for the confirmation dialog."}/>
                                        <span>Confirmation dialog title:</span>
                                    </div>
                                    <div>
                                        <TextBox value={that.state.customColumns[i].confirmationDialogTitle}
                                                 maxLength={100}
                                                 onValueChanged={(e) => that.updateValueForCustomColumn({
                                                     e,
                                                     id: that.state.customColumns[i].id,
                                                     propertyName: "confirmationDialogTitle", item: item
                                                 })}/>
                                    </div>
                                </div>
                        }
                        {
                            LDH.IsObjectNull(that.state.customColumns[i].showConfirmationDialogDescription) ||
                            that.state.customColumns[i].showConfirmationDialogDescription === false ? "" :
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon
                                            elementId={"customColumnConfirmationDialogDescription_" + i + "_help"}
                                            title={"Confirmation dialog description"}
                                            text={"Specify a description for the confirmation dialog."}/>
                                        <span>Confirmation dialog description:</span>
                                    </div>
                                    <div>
                                        <TextBox value={that.state.customColumns[i].confirmationDialogDescription}
                                                 maxLength={500}
                                                 onValueChanged={(e) => that.updateValueForCustomColumn({
                                                     e,
                                                     id: that.state.customColumns[i].id,
                                                     propertyName: "confirmationDialogDescription", item: item
                                                 })}/>
                                    </div>
                                </div>
                        }
                        {
                            LDH.IsObjectNull(that.state.customColumns[i].showConfirmationDialogDescription) ||
                            that.state.customColumns[i].showConfirmationDialogDescription === false ? "" :
                                LDH.IsObjectNull(that.state.customColumns[i].showHttpRequestType) ||
                                that.state.customColumns[i].showHttpRequestType === false ? "" :
                                    <div className="leopard-settings-panel-contentgroup">
                                        <div className="leopard-settings-panel-content-label">
                                            <LeopardTooltipWithIcon
                                                elementId={"customColumnHttpRequestType_" + i + "_help"}
                                                title={"HTTP request method"}
                                                text={"Send a method of HTTP request for sending data to the backend."}/>
                                            <span>HTTP request method:</span>
                                        </div>
                                        <div>
                                            <SelectBox dataSource={LDPH.DropdownSelectionHttpRequestType}
                                                       displayExpr={'name'}
                                                       valueExpr={'id'}
                                                       value={that.state.customColumns[i].httpRequestType}
                                                       onValueChanged={(e) => that.updateValueForCustomColumn({
                                                           e, id: that.state.customColumns[i].id,
                                                           propertyName: "httpRequestType", item: item
                                                       })}/>
                                        </div>
                                    </div>
                        }
                        {
                            LDH.IsObjectNull(that.state.customColumns[i].showQueryStringScript) ||
                            that.state.customColumns[i].showQueryStringScript === false ? "" :
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon
                                            elementId={"customColumnQueryStringScript_" + i + "_help"}
                                            title={"Query string constructor"}
                                            text={"Write a JavaScript code to create a query string for the HTTP request."}/>
                                        <span>Query string constructor:</span>
                                    </div>
                                    <div>
                                        <TextArea value={that.state.customColumns[i].queryStringScript}
                                                  height={100}
                                                  placeholder={"return \"?paramName1=parentRowData.Id\r\n&paramName2=parentRowData.Username\";"}
                                                  onValueChanged={(e) => that.updateValueForCustomColumn({
                                                      e, id: that.state.customColumns[i].id,
                                                      propertyName: "queryStringScript", item: item
                                                  })}>
                                        </TextArea>
                                    </div>
                                </div>
                        }
                        {
                            LDH.IsObjectNull(that.state.customColumns[i].showJsonDataToSend) ||
                            that.state.customColumns[i].showJsonDataToSend === false ? "" :
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon
                                            elementId={"customColumnJsonDataForHttpRequest_" + i + "_help"}
                                            title={"Request body"}
                                            text={"Specify a request body that needs to be sent by Control Centre."}/>
                                        <span>Request body:</span>
                                    </div>
                                    <div>
                                        <TextArea value={that.state.customColumns[i].jsonDataForHttpRequest}
                                                  height={100}
                                                  placeholder={"return {name1: value1, name2: value2};"}
                                                  onValueChanged={(e) => that.updateValueForCustomColumn({
                                                      e, id: that.state.customColumns[i].id,
                                                      propertyName: "jsonDataForHttpRequest", item: item
                                                  })}>
                                        </TextArea>
                                    </div>
                                </div>
                        }
                        {
                            LDH.IsObjectNull(that.state.customColumns[i].showHttpResponseCallback) ||
                            that.state.customColumns[i].showHttpResponseCallback === false ? "" :
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon
                                            elementId={"customColumnHttpResponseCallbackScript_" + i + "_help"}
                                            title={"Response data processing"}
                                            placeholder={"return {name1: value1, name2: value2};"}
                                            text={"Write a JavaScript code to process the data that is retrieved from the backend."}/>
                                        <span>Response data processing:</span>
                                    </div>
                                    <div>
                                        <TextArea value={that.state.customColumns[i].httpResponseCallbackScript}
                                                  height={100} placeholder={"return {name1: value1, name2: value2};"}
                                                  onValueChanged={(e) => that.updateValueForCustomColumn({
                                                      e, id: that.state.customColumns[i].id,
                                                      propertyName: "httpResponseCallbackScript", item: item
                                                  })}>
                                        </TextArea>
                                    </div>
                                </div>
                        }
                        {
                            !LDH.IsObjectNull(that.state.customColumns[i].showDataSourceId) &&
                            that.state.customColumns[i].showDataSourceId === false ? "" :
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon elementId={"customColumnDataSourceId_" + i + "_help"}
                                                                title={"Data source ID"}
                                                                text={"Select a data source ID for this custom column."}/>
                                        <span>Data source ID:</span>
                                    </div>
                                    <div>
                                        <SelectBox dataSource={that.props.columnFieldListForDropdown}
                                                   displayExpr={'text'}
                                                   valueExpr={'value'}
                                                   value={that.state.customColumns[i].dataSourceId}
                                                   onValueChanged={(e) => that.updateValueForCustomColumn({
                                                       e, id: that.state.customColumns[i].id,
                                                       propertyName: "dataSourceId", item: item
                                                   })}/>
                                    </div>
                                </div>
                        }
                        {
                            !LDH.IsObjectNull(that.state.customColumns[i].showVisibleCountField) &&
                            !that.state.customColumns[i].showVisibleCountField ? "" :
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon elementId={"customColumnCountColumn_" + i + "_help"}
                                                                title={"Visible/Count field"}
                                                                text={"Select a visible/count field for this custom column. If it's a Count field, " +
                                                                    "the link will be visible if the value is greater than 0. If it's a Visible field, " +
                                                                    "the link will be visible based on the 'true' or 'false' value."}/>
                                        <span>Visible/Count field:</span>
                                    </div>
                                    <div>
                                        <SelectBox dataSource={that.props.columnFieldListForDropdown}
                                                   displayExpr={'text'}
                                                   valueExpr={'value'}
                                                   value={that.state.customColumns[i].countColumnName}
                                                   onValueChanged={(e) => that.updateValueForCustomColumn({
                                                       e, id: that.state.customColumns[i].id,
                                                       propertyName: "countColumnName", item: item
                                                   })}/>
                                    </div>
                                </div>
                        }
                        {
                            !LDH.IsObjectNull(that.state.customColumns[i].showRequestURLOverrideField) &&
                            !that.state.customColumns[i].showRequestURLOverrideField ? "" :
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon elementId={"customColumnRequestURLOverride_" + i + "_help"}
                                                                title={"Request URL override"}
                                                                text={"Define a request URL for the command link."}/>
                                        <span>Request URL override:</span>
                                    </div>
                                    <div>
                                        <TextBox value={that.state.customColumns[i].requestURLOverride}
                                                 onValueChanged={(e) => that.updateValueForCustomColumn({
                                                     e,
                                                     id: that.state.customColumns[i].id,
                                                     propertyName: "requestURLOverride", item: item
                                                 })}/>
                                    </div>
                                </div>
                        }
                        {
                            !LDH.IsObjectNull(that.state.customColumns[i].showThumbnailField) &&
                            that.state.customColumns[i].showThumbnailField === false ? "" :
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon
                                            elementId={"customColumnShowThumbnail_" + i + "_help"}
                                            title={"Show thumbnails"}
                                            text={"Specify whether to show the thumbnails on the popup form."}/>
                                        <span>Show thumbnails:</span>
                                    </div>
                                    <div>
                                        <SelectBox dataSource={LDPH.DropdownSelectionYesNo} displayExpr={'name'}
                                                   valueExpr={'id'}
                                                   value={that.state.customColumns[i].showThumbnails}
                                                   onValueChanged={(e) => that.updateValueForCustomColumn({
                                                       e, id: that.state.customColumns[i].id,
                                                       propertyName: "showThumbnails", item: item
                                                   })}/>
                                    </div>
                                </div>
                        }
                        {
                            LDH.IsObjectNull(that.state.customColumns[i].showPostDataProcessingField) ||
                            that.state.customColumns[i].showPostDataProcessingField === false ? "" :
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon
                                            elementId={"customColumnPostDataProcessing_" + i + "_help"}
                                            title={"Request URL data processing"}
                                            text={"Write a JavaScript code to process the data that is retrieved from the backend."}/>
                                        <span>Request URL data processing:</span>
                                    </div>
                                    <div>
                                        <TextArea value={that.state.customColumns[i].requestURLOverrideDataProcessing}
                                                  height={100}
                                                  onValueChanged={(e) => that.updateValueForCustomColumn({
                                                      e, id: that.state.customColumns[i].id,
                                                      propertyName: "requestURLOverrideDataProcessing", item: item
                                                  })}>
                                        </TextArea>
                                    </div>
                                </div>
                        }
                        {
                            LDH.IsObjectNull(that.state.customColumns[i].showEditFormFields) ||
                            that.state.customColumns[i].showEditFormFields === false ? "" :
                                <React.Fragment>
                                    <div className="leopard-settings-panel-contentgroup">
                                        <div className="leopard-settings-panel-content-label">
                                            <LeopardTooltipWithIcon
                                                elementId={"disabledFieldsForPopupEditor_" + i + "_help"}
                                                title={"Readonly fields"}
                                                text={"Specifies which columns on the grid view should be readonly when a user editing a data row."}/>
                                            <span>Readonly fields:</span>
                                        </div>
                                        <div>
                                            <LeopardDesignerTagBox
                                                dropdownList={that.props.columnFieldListForDropdown}
                                                fieldValue={that.state.customColumns[i].disabledFields}
                                                fieldType={"commandlink-disabled-fields"} uiRef={null}
                                                leopardDesignerTagBoxValueOnChange={
                                                    (e) => that.updateValueForCustomColumn({
                                                        e, id: that.state.customColumns[i].id,
                                                        propertyName: "disabledFields", item: item
                                                    })
                                                }/>
                                        </div>
                                    </div>
                                    <div className="leopard-settings-panel-contentgroup">
                                        <div className="leopard-settings-panel-content-label">
                                            <LeopardTooltipWithIcon
                                                elementId={"invisibleFieldsForPopupEditor_" + i + "_help"}
                                                title={"Invisible fields"}
                                                text={"Specifies which columns on the grid view should be hidden when a user editing a data row."}/>
                                            <span>Invisible fields:</span>
                                        </div>
                                        <div>
                                            <LeopardDesignerTagBox
                                                dropdownList={that.props.columnFieldListForDropdown}
                                                fieldValue={that.state.customColumns[i].invisibleFields}
                                                fieldType={"commandlink-invisible-fields"} uiRef={null}
                                                leopardDesignerTagBoxValueOnChange={
                                                    (e) => that.updateValueForCustomColumn({
                                                        e, id: that.state.customColumns[i].id,
                                                        propertyName: "invisibleFields", item: item
                                                    })
                                                }/>
                                        </div>
                                    </div>
                                    <div className="leopard-settings-panel-contentgroup">
                                        <div className="leopard-settings-panel-content-label">
                                            <LeopardTooltipWithIcon
                                                elementId={"hiddenFieldsForPopupEditor_" + i + "_help"}
                                                title={"Disabled fields"}
                                                text={"Specifies which columns on the grid view should be disabled and hidden when a user editing a data row."}/>
                                            <span>Disabled fields:</span>
                                        </div>
                                        <div>
                                            <LeopardDesignerTagBox
                                                dropdownList={that.props.columnFieldListForDropdown}
                                                fieldValue={that.state.customColumns[i].hiddenFields}
                                                fieldType={"commandlink-hidden-fields"} uiRef={null}
                                                leopardDesignerTagBoxValueOnChange={
                                                    (e) => that.updateValueForCustomColumn({
                                                        e, id: that.state.customColumns[i].id,
                                                        propertyName: "hiddenFields", item: item
                                                    })
                                                }/>
                                        </div>
                                    </div>
                                </React.Fragment>
                        }
                        {
                            LDH.IsObjectNull(that.state.customColumns[i].showEditFormFields) ||
                            that.state.customColumns[i].showEditFormFields === false ? "" :
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon elementId={"customColumnGenericColumns_" + i + "_help"}
                                                                title={"Custom fields"}
                                                                text={"Specify custom fields for the Form Editor."}/>
                                        <span>Custom fields:</span>
                                    </div>
                                    <div>
                                        <TextArea value={that.state.customColumns[i].customFields} height={100}
                                                  className={"textbox_MiniButton"}
                                                  onValueChanged={(e) => that.updateValueForCustomColumn({
                                                      e, id: that.state.customColumns[i].id,
                                                      propertyName: "customFields", item: item
                                                  })}>
                                            <TextBoxButton
                                                name={"customColumnGenericColumns_" + i + "_MiniButton"}
                                                location={'after'}
                                                options={{
                                                    icon: "fas fa-question",
                                                    type: 'default',
                                                    onClick: () => {
                                                        alert(
                                                            "<div style={{width: '100px'}}>[<br/>" +
                                                            "    &nbsp;&nbsp;&nbsp;{<br/>" +
                                                            "        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\"fieldName\": \"CustomField1\",<br/>" +
                                                            "        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\"displayName\": \"Custom Field 1\",<br/>" +
                                                            "        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\"fieldType\": \"string\",<br/>" +
                                                            "        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\"readOnly\": true,<br/>" +
                                                            "        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\"invisible\": false,<br/>" +
                                                            "        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\"defaultValue\": \"\",<br/>" +
                                                            "        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\"validationRules\": [{<br/>" +
                                                            "        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\"regex\": \"^[^0-9]+$\",<br/>" +
                                                            "        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\"error\": \"Digits not allowed\"<br/>" +
                                                            "        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;},{<br/>" +
                                                            "        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\"regex\": \"^\\\\+\\\\s*1\\\\s*\\\\(\\\\s*[02-9]\\\\d{2}\\\\)\\\\s*\\\\d{3}\\\\s*-\\\\s*\\\\d{4}$\",<br/>" +
                                                            "        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\"error\": \"Invalid USA phone format\"<br/>" +
                                                            "        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;},{<br/>" +
                                                            "        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\"regex\": \"[required]\",<br/>" +
                                                            "        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\"error\": \"This field is required\"<br/>" +
                                                            "        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;}]<br/>" +
                                                            "    &nbsp;&nbsp;&nbsp;}<br/>" +
                                                            "]</div>", "Example of custom fields")
                                                    }
                                                }}
                                            />
                                        </TextArea>
                                    </div>
                                </div>
                        }
                        {
                            LDH.IsObjectNull(that.state.customColumns[i].showTemplateDirectory) ||
                            that.state.customColumns[i].showTemplateDirectory === false ? "" :
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon
                                            elementId={"customColumnTemplateDirectory_" + i + "_help"}
                                            title={"Template directory"}
                                            text={"Specify a template directory for data submission."}/>
                                        <span>Template directory:</span>
                                    </div>
                                    <div>
                                        <TextBox value={that.state.customColumns[i].customColumnTemplateDirectory}
                                                 maxLength={500}
                                                 onValueChanged={(e) => that.updateValueForCustomColumn({
                                                     e,
                                                     id: that.state.customColumns[i].id,
                                                     propertyName: "customColumnTemplateDirectory", item: item
                                                 })}/>
                                    </div>
                                </div>
                        }
                        {
                            LDH.IsObjectNull(that.state.customColumns[i].showTemplateFilename) ||
                            that.state.customColumns[i].showTemplateFilename === false ? "" :
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon
                                            elementId={"customColumnTemplateFilename_" + i + "_help"}
                                            title={"Template file name:"}
                                            text={"Specify a template file name for data submission."}/>
                                        <span>Template file name:</span>
                                    </div>
                                    <div>
                                        <TextBox value={that.state.customColumns[i].customColumnTemplateFilename}
                                                 maxLength={500}
                                                 onValueChanged={(e) => that.updateValueForCustomColumn({
                                                     e,
                                                     id: that.state.customColumns[i].id,
                                                     propertyName: "customColumnTemplateFilename", item: item
                                                 })}/>
                                    </div>
                                </div>
                        }
                        {
                            !LDH.IsObjectNull(that.state.customColumns[i].showIteration) &&
                            that.state.customColumns[i].showIteration === false ? "" :
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon elementId={"customColumnDataSourceId_" + i + "_help"}
                                                                title={"Enable iteration"}
                                                                text={"Iterate through an array of data and submit them separately."}/>
                                        <span>Enable iteration:</span>
                                    </div>
                                    <div>
                                        <SelectBox dataSource={LDPH.DropdownSelectionYesNo}
                                                   displayExpr={'name'} valueExpr={'id'}
                                                   value={that.state.customColumns[i].enableIteration}
                                                   onValueChanged={(e) => that.updateValueForCustomColumn({
                                                       e, id: that.state.customColumns[i].id,
                                                       propertyName: "enableIteration", item: item
                                                   })}/>
                                    </div>
                                </div>
                        }
                        {
                            LDH.IsObjectNull(that.state.customColumns[i].showAutoRefreshTimeAfterSubmit) ||
                            that.state.customColumns[i].showAutoRefreshTimeAfterSubmit === false ? "" :
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon
                                            elementId={"customColumnAutoRefreshTimeAfterSubmit_" + i + "_help"}
                                            title={"Auto refresh"}
                                            text={"Set the countdown timer for the auto refresh."}/>
                                        <span>Auto refresh (seconds):</span>
                                    </div>
                                    <div>
                                        <NumberBox value={that.state.customColumns[i].autoRefreshTimeAfterSubmit}
                                                   showSpinButtons={true} min={1} max={60} format={"#"}
                                                   showClearButton={true}
                                                   onValueChanged={(e) => that.updateValueForCustomColumn({
                                                       e, id: that.state.customColumns[i].id,
                                                       propertyName: "autoRefreshTimeAfterSubmit", item: item
                                                   })}/>
                                    </div>
                                </div>
                        }
                    </div>
                </Item>
            );
        });

        return (
            <Box direction={'row'} width={'100%'}>
                <Item baseSize={300}>
                    <div className="leopard-settings-panel-cell">
                        <div style={{float: "right", width: "22px", textAlign: "center", marginRight: "10px"}}>
                            <i className="leopard-configurepanel-title-button fas fa-plus"
                               onClick={(e) => this.generalAddColumnOnClick(e)}></i>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon elementId={"customColumnOverallWidth_help"}
                                                        title={"Column width"}
                                                        text={"Specify the overall width for custom columns."}/>
                                <span>Column width:</span>
                            </div>
                            <div>
                                <NumberBox value={this.state.customColumnOverallWidth} showSpinButtons={true}
                                           min={10} max={9999} format={"#"}
                                           onValueChanged={(e) => this.customColumnOverallWidthOnChange(e)}/>
                            </div>
                        </div>
                    </div>
                </Item>
                {result}
            </Box>
        )
    }
}

export default LeopardCommandLinks;
