import React, {Component} from 'react';
import {connect} from 'react-redux';
import LRH from '../helpers/LeopardReactHelper';
import LDH from '../helpers/LeopardDataHelper';
import LeopardStaticUIConfig from "./LeopardStaticUIConfig";
import {Button, TextArea, TileView} from "devextreme-react";
import LeopardAjaxHelper from "../helpers/LeopardAjaxHelper";
import TabPanel from "devextreme-react/tab-panel";
import LeopardDropdownHelper from "../helpers/LeopardDropdownHelper";
import LeopardAPIGatewayConfig from "./LeopardAPIGatewayConfig";

class LeopardAttachableWidgetEngine extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customStore: null,
            showLoadingProgress: false,
            disableWidget: false,
            photoData: [],
            messageValue: "",
            messageToUser: ""
        };

        this.uiObjectInstances = {};
        this.disposingAllInstances = false;
        this.relationships = [];
        this.relationshipsLinkedToDataView = [];
        this.selectedParentViewData = null;
    }

    setObjectInstance = (data) => {
        if (LDH.IsObjectNull(data.ref) || LDH.IsObjectNull(data.ref.instance) ||
            !LDH.IsObjectNull(this.uiObjectInstances[data.controlName])) {
            return false;
        }
        this.uiObjectInstances[data.controlName] = data.ref.instance;
        return true;
    };

    setNotepadInstance = (dataObj) => {
        let that = this;
        if (this.setObjectInstance(dataObj) === false) {
            return;
        }

        this.props.setNotepadInstance({
            instance: dataObj.ref.instance,
            id: this.props.dataViewId,
            type: "notepad",
            isDataView: true
        });

        that.relationships = that.props.relationships;
        let dashboardItemId = that.props.dataViewId;

        if (!LDH.IsObjectNull(dashboardItemId) && !LDH.IsValueEmpty(dashboardItemId) &&
            !LDH.IsObjectNull(that.relationships) &&
            that.relationshipsLinkedToDataView.length === 0) {
            let linkedList = LDH.GetRelationshipsByDashboardItemId(that.relationships,
                dashboardItemId);
            that.relationshipsLinkedToDataView = linkedList;
            that.uiObjectInstances[dataObj.controlName].option("relationships", linkedList);
        }

        that.selectedParentViewData = null;
        if (!LDH.IsObjectNull(dashboardItemId) && !LDH.IsValueEmpty(dashboardItemId) &&
            !LDH.IsObjectNull(that.relationships)) {
            LeopardStaticUIConfig.Global_DashboardDataViewListeners.push({
                dashboardItemId,
                props: that.props,
                instance: dataObj.ref.instance,
                callback(data) {
                    let messageToUser = "";
                    for (let i = 0; i < data.features.length; i++) {
                        if (data.features[i] === "rowlink") {
                            that.selectedParentViewData = data.dataFromSource;
                            if (!LDH.IsObjectNull(data.dataFromSource) &&
                                !LDH.IsObjectNull(data.dataFromSource["Name"]) &&
                                !LDH.IsValueEmpty(data.dataFromSource["Name"])) {
                                messageToUser = data.dataFromSource["Name"];
                            }

                            let firstName = "";
                            let lastName = "";
                            if (!LDH.IsObjectNull(data.dataFromSource) &&
                                !LDH.IsObjectNull(data.dataFromSource["FirstName"]) &&
                                !LDH.IsValueEmpty(data.dataFromSource["FirstName"])) {
                                firstName = data.dataFromSource["FirstName"];
                            }
                            if (!LDH.IsObjectNull(data.dataFromSource) &&
                                !LDH.IsObjectNull(data.dataFromSource["LastName"]) &&
                                !LDH.IsValueEmpty(data.dataFromSource["LastName"])) {
                                lastName = data.dataFromSource["LastName"];
                            }
                            if (!LDH.IsValueEmpty(firstName) && !LDH.IsValueEmpty(lastName)) {
                                messageToUser += " (" + firstName + " " + lastName + ")";
                            }
                        }
                    }
                    that.setState({
                        disableWidget: LDH.IsObjectNull(that.selectedParentViewData),
                        messageToUser: messageToUser
                    });
                }
            });
        } else {
            that.setState({
                disableWidget: LDH.IsObjectNull(that.selectedParentViewData)
            });
        }
    };

    setPhotoInstance = (dataObj) => {
        let that = this;
        if (this.setObjectInstance(dataObj) === false) {
            return;
        }

        this.props.setPhotoInstance({
            instance: dataObj.ref.instance,
            id: this.props.dataViewId,
            type: "photo",
            isDataView: true
        });

        that.relationships = that.props.relationships;
        let dashboardItemId = that.props.dataViewId;

        if (!LDH.IsObjectNull(dashboardItemId) && !LDH.IsValueEmpty(dashboardItemId) &&
            !LDH.IsObjectNull(that.relationships) &&
            that.relationshipsLinkedToDataView.length === 0) {
            let linkedList = LDH.GetRelationshipsByDashboardItemId(that.relationships,
                dashboardItemId);
            that.relationshipsLinkedToDataView = linkedList;
            that.uiObjectInstances[dataObj.controlName].option("relationships", linkedList);
        }

        that.selectedParentViewData = null;
        if (!LDH.IsObjectNull(dashboardItemId) && !LDH.IsValueEmpty(dashboardItemId) &&
            !LDH.IsObjectNull(that.relationships)) {
            LeopardStaticUIConfig.Global_DashboardDataViewListeners.push({
                dashboardItemId,
                props: that.props,
                instance: dataObj.ref.instance,
                callback(data) {
                    let photoDefinition = that.props.definition.photoDefinition;
                    let id = photoDefinition.parentDataSourceId;
                    for (let i = 0; i < data.features.length; i++) {
                        if (data.features[i] === "rowlink") {
                            let allowErrorMessage = false;
                            that.selectedParentViewData = data.dataFromSource;
                            if (!LDH.IsObjectNull(data.dataFromSource) && !LDH.IsObjectNull(data.parentGridDef) &&
                                !LDH.IsObjectNull(data.parentGridDef.customColumnConfiguration) &&
                                !LDH.IsObjectNull(data.parentGridDef.customColumnConfiguration.customColumns)) {
                                for (let v = 0; v < data.parentGridDef.customColumnConfiguration.customColumns.length; v++) {
                                    let customColumn = data.parentGridDef.customColumnConfiguration.customColumns[v];
                                    if (!LDH.IsObjectNull(customColumn.countColumnName) &&
                                        !LDH.IsValueEmpty(customColumn.countColumnName) &&
                                        customColumn.columnType === "photo-gallery" &&
                                        !LDH.IsValueEmpty(data.dataFromSource[customColumn.countColumnName]) &&
                                        parseInt(data.dataFromSource[customColumn.countColumnName]) > 0) {
                                        allowErrorMessage = true;
                                    }
                                }
                            }
                            if (LDH.IsObjectNull(data.dataFromSource) === false) {
                                that.getDataFromDataSource(data.dataFromSource[id], allowErrorMessage);
                            } else {
                                that.getDataFromDataSource(null, allowErrorMessage);
                            }
                        }
                    }
                }
            });
        }
    };

    componentWillUnmount = () => {
        this.disposingAllInstances = true;
        LRH.DisposeUIInstancesFromList(this.uiObjectInstances);

        if (!LDH.IsObjectNull(this.props.setPhotoInstance) &&
            typeof this.props.setPhotoInstance === "function") {
            this.props.setPhotoInstance({
                instance: null,
                id: this.props.dataViewId,
                type: "photo",
                isDataView: true
            });
        }

        if (!LDH.IsObjectNull(this.props.setNotepadInstance) &&
            typeof this.props.setNotepadInstance === "function") {
            this.props.setNotepadInstance({
                instance: null,
                id: this.props.dataViewId,
                type: "notepad",
                isDataView: true
            });
        }
    };

    componentDidMount = () => {
        this.relationships = this.props.relationships;
    };

    messageValueOnChanged = (e) => {
        this.setState({messageValue: e.value});
    };

    getDataFromDataSource = (itemId, allowErrorMessage) => {
        let that = this;
        if (that.disposingAllInstances === true) {
            return;
        }
        if (LDH.IsObjectNull(itemId) || LDH.IsValueEmpty(itemId)) {
            that.setState({photoData: []});
            return;
        }
        LeopardAjaxHelper.GetAttachmentsByItemId(itemId, allowErrorMessage, function (result) {
            if (that.disposingAllInstances === true) {
                return;
            }
            let items = LDH.GetImageListFromResult(result, itemId);
            that.setState({photoData: items});
        }, function () {
            if (that.disposingAllInstances === false) {
                LRH.ShowToast("Failed to retrieve photos from the server.", "error", 5000);
            }
        }, "", null);
    };

    photoOnClick = (e) => {
        window.open(e.imageData.e.originalImageUrl);
    };

    notepadSubmitButtonOnClick = (e) => {
        let that = this;
        if (LDH.IsObjectNull(that.selectedParentViewData) ||
            LDH.IsObjectNull(that.selectedParentViewData["Name"]) ||
            LDH.IsValueEmpty(that.selectedParentViewData["Name"])) {
            LRH.ShowToast("Please select a recipient from the parent data.", "error", 5000);
            return;
        }
        if (LDH.IsObjectNull(that.state.messageValue) ||
            LDH.IsValueEmpty(that.state.messageValue)) {
            LRH.ShowToast("Your text message cannot be blank.", "error", 5000);
            return;
        }
        that.setState({showLoadingProgress: true}, function () {
            let postTemplate = LDH.DeepClone(LeopardAPIGatewayConfig.ProfileAPI_BodyTemplate());
            postTemplate.type = "leopardsystems.chat.send";
            postTemplate.source = LDH.GenerateGuid();

            let postData = {
                "recipients": [{
                    "id": that.selectedParentViewData["Name"],
                    "type": "user"
                }],
                "message": that.state.messageValue
            };

            let notepadDefinition = this.props.definition.notepadDefinition;
            LeopardAjaxHelper.SendRequestByEventSync(function () {
                if (!LDH.IsObjectNull(notepadDefinition.notepadDataSubmissionLogic) &&
                    !LDH.IsValueEmpty(notepadDefinition.notepadDataSubmissionLogic)) {
                    let javascript = notepadDefinition.notepadDataSubmissionLogic;
                    let dataName = "parentRowData";
                    let dataValue = "";
                    LDH.EvaluateJavaScriptForDataShaping(javascript, dataName,
                        dataValue, that.props.dataViewId, null);
                }
                that.setState({showLoadingProgress: false, messageValue: ""});
            }, function (error, sessionTimeout) {
                if (sessionTimeout !== undefined && sessionTimeout === true) {
                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                } else {
                    LRH.ShowToast("Failed to send a message to the recipient.", "error", 5000);
                }
                that.setState({showLoadingProgress: false});
            }, postTemplate, postData);
        });
    };

    tileViewItem = (data) => {
        return (
            <React.Fragment>
                <div className={"leopard-thumbnail-image"}
                     style={{width: "100%", height: "100%", padding: "5px"}}>
                    <div className={data.e.id + " leopard-tileviewitem-image"}
                         onClick={(e) => this.photoOnClick({e, imageData: data})}
                         style={{backgroundImage: `url(${data.e.imageUrl})`}}>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    tabItemTitleRender = (data) => {
        return (
            <React.Fragment>
                <span>{data.tabTitle}</span>
            </React.Fragment>
        );
    };

    tabItemContent = (data) => {
        let that = this;

        if (data.e.tabId === 0) {
            return (
                <React.Fragment>
                    <div className={"leopard-photogallery-data-view"}>
                        <TileView dataSource={that.state.photoData} height={"100%"}
                                  baseItemHeight={data.photoDefinition.heightOfThumbnail}
                                  baseItemWidth={data.photoDefinition.widthOfThumbnail}
                                  noDataText={"No photo available to display."}
                                  ref={(e) => that.setPhotoInstance({
                                      ref: e, controlName: "photo_" + that.props.dataViewId
                                  })}
                                  direction={"vertical"} itemMargin={0}
                                  itemComponent={(e) => that.tileViewItem({
                                      e, definition: that.props.definition
                                  })}>
                        </TileView>
                    </div>
                </React.Fragment>
            );
        } else if (data.e.tabId === 1) {
            return (
                <React.Fragment>
                    <div style={{height: "100%"}}>
                        <div style={{padding: "10px"}}>
                            This feature is currently under development.
                        </div>
                    </div>
                </React.Fragment>
            );
        }
        return null;
    };

    render() {
        if (this.disposingAllInstances) return null;

        if (this.props.dashboardItemType === "notepad") {
            let notepadDefinition = this.props.definition.notepadDefinition;
            return (
                <React.Fragment>
                    <div style={{padding: "10px", height: "100%"}}>
                        <TextArea disabled={this.state.showLoadingProgress || this.state.disableWidget}
                                  height={"calc(100% - 45px)"} value={this.state.messageValue}
                                  onValueChanged={(e) => this.messageValueOnChanged(e)}
                                  ref={(e) => this.setNotepadInstance({
                                      ref: e, controlName: "notepad_messagebox_" + this.props.dataViewId
                                  })} maxLength={1000}
                                  placeholder={"Enter your message here..."}>
                        </TextArea>
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <div className={"leopard-messagewidget-messageto-text"}
                                 style={{visibility: LDH.IsValueEmpty(this.state.messageToUser) ? "hidden" : "visible"}}>
                                <span style={{fontWeight: "bold", marginRight: "5px"}}>Message To:</span>
                                <span>{this.state.messageToUser}</span>
                            </div>
                            <div style={{marginTop: "10px", display: "flex"}}>
                            <span className={"leopard-loading-icon notepad_" + this.props.dataViewId} style={{
                                marginRight: "15px",
                                marginLeft: "30px",
                                display: this.state.showLoadingProgress ? "block" : "none"
                            }}>
                                <i className="fas fa-spinner fa-pulse" style={{color: "#FF8100", fontSize: "25px"}}></i>
                            </span>
                                <Button className="leopard-button auto-width with-padding"
                                        text={notepadDefinition.notepadButtonText}
                                        disabled={this.state.showLoadingProgress || this.state.disableWidget}
                                        ref={(e) => this.setObjectInstance({
                                            ref: e, controlName: "notepad_submitbutton_" + this.props.dataViewId
                                        })} onClick={(e) => this.notepadSubmitButtonOnClick(e)}>
                                </Button>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        } else {
            let photoDefinition = this.props.definition.photoDefinition;
            return (
                <React.Fragment>
                    <div className={"leopard-attachment-widget-container"}>
                        <TabPanel dataSource={LeopardDropdownHelper.DropdownSelectionAttachmentTabPanel}
                                  animationEnabled={true} swipeEnabled={false} showNavButtons={true}
                                  itemTitleRender={this.tabItemTitleRender}
                                  itemComponent={(e) => this.tabItemContent({
                                      e, photoDefinition
                                  })}>
                        </TabPanel>
                    </div>
                </React.Fragment>
            );
        }
    }
}

const RetrieveDataFromReducer = (state) => {
    return {state};
};

export default connect(RetrieveDataFromReducer)(LeopardAttachableWidgetEngine);
