import React from 'react';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.css';
import {connect} from 'react-redux';
import LeopardSecurity from './security/LeopardSecurity';
import LRH from './helpers/LeopardReactHelper';
import {
    SetAuthenticatedUser,
    SetLeopardAdminMode,
    SetUserPermissions,
    SetWebAppInitialized
} from './foundation/LeopardActionCreators';

import {Button, TextBox} from 'devextreme-react';
import LeopardTopMenuBar from './components/LeopardTopMenuBar';
import LeopardMasterLeftMenu from './components/LeopardMasterLeftMenu';
import LeopardStaticUIConfig from './foundation/LeopardStaticUIConfig';
import LeopardMasterContentPanel from './components/LeopardMasterContentPanel';
import LeopardAjaxHelper from './helpers/LeopardAjaxHelper';
import LDH from './helpers/LeopardDataHelper';
import config from 'devextreme-react/core/config';
import LeopardWebsocketHelper from './helpers/LeopardWebsocketHelper';
import LeopardAPIGatewayConfig from "./foundation/LeopardAPIGatewayConfig";

require("bootstrap");
config({useLegacyTemplateEngine: true});

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loggedInUserName: "",
            loggedInUserRoles: "",
            authState: null,
            masterPageOnLoadComplete: false
        };

        this.gridViewInstance = null;
        this.chartInstance = null;
        this.timelineInstance = null;
        this.notepadInstance = null;
        this.documentEditorInstance = null;
        this.photoInstance = null;
        this.leftMenuTreeViewInstance = null;
        this.showLeftMenuContent = true;
    }

    updateWindowDimensionsOnResizing = (optimizePagerForLargeDataset) => {
        let that = this;

        let showGridViewAdminToolbar = false;
        if (!LDH.IsObjectNull(this.props.state.permissions)) {
            let permissions = this.props.state.permissions;
            showGridViewAdminToolbar = permissions.ShowGridViewAdminToolbar;
        }

        let height = window.innerHeight - 153;
        let width = window.innerWidth;
        width = width - (that.showLeftMenuContent ? 305 : 0);

        if (!LDH.IsObjectNull(this.gridViewInstance)) {
            let heightForGridView = window.innerHeight;
            heightForGridView = heightForGridView - 153;

            let adjustment = 0;
            if ((LDH.IsValueEmpty(optimizePagerForLargeDataset) === false &&
                    optimizePagerForLargeDataset === true) ||
                $(".leopard-page-container").length > 0) {
                adjustment = -33;
            }

            if (showGridViewAdminToolbar === true) {
                this.gridViewInstance.option("height", heightForGridView + adjustment - 3);
            } else {
                this.gridViewInstance.option("height", heightForGridView + adjustment + 27);
            }

            if (that.gridViewInstance.getVisibleRows().length > 0) {
                that.gridViewInstance.repaint();
            }
        }

        if (!LDH.IsObjectNull(this.chartInstance)) {
            let heightForChart = window.innerHeight - 128;
            if (showGridViewAdminToolbar === true) {
                this.chartInstance.option("size", {height: heightForChart - 30});
            } else {
                this.chartInstance.option("size", {height: heightForChart});
            }
        }

        if (!LDH.IsObjectNull(this.timelineInstance)) {
            this.timelineInstance.repaint();
        }

        if (!LDH.IsObjectNull(this.mapInstance)) {
            let heightForMap = window.innerHeight - 95;
            this.mapInstance.option("height", heightForMap);
        }

        let winHeight = height + 68;
        $(".leopard-right-panel-container").css("height", winHeight);
        $(".leopard-left-panel-container").css("height", winHeight);
        $(".leopard-leftmenu-option-panel").css("height", winHeight);
        $(".leopard-option-panel-content").css("height", winHeight - 110);
        $(".leopard-screen-cover").css("height", winHeight);

        $(".leopard-table-with-fixedcolumn table").css("width", width - 50);
        $(".leopard-table-with-fixedcolumn thead").css("width", width - 50);
        $(".leopard-table-with-fixedcolumn tbody").css("width", width - 50);

        let $reportDesigner = $("#LeopardReportDesigner");
        if ($reportDesigner.length > 0 && $reportDesigner.css("z-index") !== "1000000") {
            $reportDesigner.css("height", winHeight - 10);
        }
    };

    setGridViewInstance = (e) => {
        if (e.isDataView === true) {
            this.gridViewInstance = e.instance;
            this.updateWindowDimensionsOnResizing(e.optimizePagerForLargeDataset);
        }
    };

    setLeftMenuTreeViewInstance = (e) => {
        this.leftMenuTreeViewInstance = e.instance;
    };

    setChartInstance = (e) => {
        if (e.isDataView === true) {
            this.chartInstance = e.instance;
            this.updateWindowDimensionsOnResizing(false);
        }
    };

    setTimelineInstance = (e) => {
        if (e.isDataView === true) {
            this.timelineInstance = e.instance;
            this.updateWindowDimensionsOnResizing(false);
        }
    };

    setPhotoInstance = (e) => {
        if (e.isDataView === true) {
            this.photoInstance = e.instance;
            this.updateWindowDimensionsOnResizing(false);
        }
    };

    setNotepadInstance = (e) => {
        if (e.isDataView === true) {
            this.notepadInstance = e.instance;
            this.updateWindowDimensionsOnResizing(false);
        }
    };

    setDocumentEditorInstance = (e) => {
        if (e.isDataView === true) {
            this.documentEditorInstance = e.instance;
            this.updateWindowDimensionsOnResizing(false);
        }
    };

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensionsOnResizing);
        this.gridViewInstance = null;
        this.showLeftMenuContent = true;
    }

    componentDidUpdate = () => {
        let that = this;
        if (this.props.updateUserProfile === true) {
            this.props.updateUserProfileComplete(function () {
                that.initializeAuthenticatedUserAndProfile();
            });
        }
    };

    leftMenuExpandAll = (thisComp) => {
        thisComp.leftMenuTreeViewInstance.expandAll();
    }

    leftMenuExpandOnSelection = (thisComp) => {
        for (let i = 0; i < window.leftMenuUIState.length; i++) {
            let key = window.leftMenuUIState[i].key;
            if (window.leftMenuUIState[i].state === "collapsed") {
                thisComp.leftMenuTreeViewInstance.collapseItem(key);
            }
            if (window.leftMenuUIState[i].state === "expanded") {
                thisComp.leftMenuTreeViewInstance.expandItem(key);
            }
        }
        $("#dxMenuTreeView").show();
    }

    componentDidMount() {
        window.leftMenuUIState = [{key: "directory_predefined_workspaces", status: "expanded"}];
        let version = LDH.ReplaceAll(LeopardStaticUIConfig.ControlCentreVersion, ".", "");

        let fontStyle = '<link rel="stylesheet" type="text/css" href="/css/leopard-theme-fonts.css?v=' + version + '" />';
        $("head").append(fontStyle);

        let devextremeStyle = '<link rel="stylesheet" type="text/css" href="/css/devextreme/dx.generic.leopard-theme.css?v=' + version + '" />';
        $("head").append(devextremeStyle);

        let customStyle = '<link id="leopard-default-style" rel="stylesheet" type="text/css" href="/css/leopard-theme-colors.css?v=' + version + '" />';
        $("#leopard-default-style").remove();
        $("head").append(customStyle);

        let defaultStyle = '<link rel="stylesheet" type="text/css" href="/css/leopard-theme-style.css?v=' + version + '" />';
        $("head").append(defaultStyle);

        $("head").append('<script src="/js/webconfig.js?v=' + version + '"></script>');
        window.addEventListener("resize", this.updateWindowDimensionsOnResizing);

        $("body").on("contextmenu", function (e) {
            if (!LDH.IsObjectNull(e.target) && !LDH.IsValueEmpty(e.target.className) &&
                e.target.className === "leopard-photogallery-enlarged-image") {
                return true;
            }
            return false;
        });

        $(document).off("click").on("click", function () {
            if ($(".leopard-login-userinfo-panel").is(":visible")) {
                $(".leopard-login-userinfo-panel").hide();
            }
        });

        window.currentMousePos = {};
        $(document).mousemove(function (event) {
            window.currentMousePos["x"] = event.pageX;
            window.currentMousePos["y"] = event.pageY;
        });

        let that = this;
        this.setState({masterPageOnLoadComplete: true}, function () {
            that.updateWindowDimensionsOnResizing();
        });
        LeopardAjaxHelper.AddSecurityHeadersToAjaxRequestForOdata();
    }

    initializeAuthenticatedUserAndProfile = () => {
        let that = this;

        if (that.props.authState === "signedIn") {
            LeopardSecurity.GetCurrentAuthenticatedUser(function (user) {
                let roles = user.attributes["custom:role"];

                that.props.SetAuthenticatedUser({data: user});
                that.setState({
                    loggedInUserName: user.username,
                    authState: that.props.authState,
                    loggedInUserRoles: roles
                });

                if (roles === LeopardStaticUIConfig.UserRoleCCAdmin) {
                    that.props.SetUserPermissions({
                        ShowGridViewAdminToolbar: true,
                        AllowReportDesigner: true
                    });
                    that.props.SetLeopardAdminMode({enabled: false});
                } else {
                    that.props.SetUserPermissions({
                        ShowGridViewAdminToolbar: false,
                        AllowReportDesigner: false
                    });
                    that.props.SetLeopardAdminMode({enabled: false});
                }

                let userId = user.attributes.sub;
                window.userRoles = roles;

                if (roles === LeopardStaticUIConfig.UserRoleAppUser) {
                    setTimeout(function () {
                        $(".leopard-left-panel-container .leopard-loading-icon").hide();
                        LRH.ShowToast("You're logged in as an \"App User\" which do not have access to Control Centre. " +
                            "Please contact your administrator for details.", "error", 8000);
                    }, 100);

                    setTimeout(function () {
                        LeopardSecurity.UserLogout();
                    }, 5000);
                    return;
                }
                LeopardAjaxHelper.GetUserProfile(userId, function (profile) {
                    window.userProfile = profile;

                    that.props.SetWebAppInitialized({data: true});
                    let orgId = LDH.GetOrganizationIdFromUserProfile(profile);
                    that.getUserAttributes(profile, 0);

                    let templateList = LeopardAPIGatewayConfig.ProfileAPI_BodyTemplate();
                    templateList.id = LDH.GenerateGuid();
                    templateList.type = "leopardsystems.document.retrieve";

                    let requestListData = {
                        name: "cc_global_config.json",
                        type: "reference",
                        owner: userId
                    };

                    // LeopardAjaxHelper.SendRequestByEventSync(function (response) {
                    //     let request = new XMLHttpRequest();
                    //     let url = response.body.data[0].url;
                    //     request.open("GET", url);
                    //     request.send();
                    //
                    //     request.onreadystatechange = function () {
                    //         if (request.readyState == 4) {
                    //             if (!LDH.IsJsonString(request.responseText)) {
                    //                 $(".leopard-right-panel-container").css("background-image",
                    //                     "url(" + LeopardStaticUIConfig.LeopardCubeLogoBase64 + ")");
                    //                 $(".leopard-right-panel-container").css("background-size", "300px 300px");
                    //                 return;
                    //             }
                    //             let resultJSON = JSON.parse(request.responseText);
                    //
                    //             if (!LDH.IsValueEmpty(resultJSON.cc_organisation_applogo)) {
                    //                 LeopardAjaxHelper.RetrieveDocumentFromS3(userId, resultJSON.cc_organisation_applogo,
                    //                     "reference", function (documentData) {
                    //                         $(".leopard-right-panel-container").css("background-image", "url(" + documentData + ")");
                    //                     }, function (error, sessionTimeout) {
                    //                         if (sessionTimeout !== undefined && sessionTimeout === true) {
                    //                             LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    //                         } else {
                    //                             LRH.ShowToast("Failed to retrieve the global config.", "error", 5000);
                    //                         }
                    //                     });
                    //             }
                    //
                    //             if (!LDH.IsValueEmpty(resultJSON.cc_organisation_applogo_width) &&
                    //                 !LDH.IsValueEmpty(resultJSON.cc_organisation_applogo_height)) {
                    //                 $(".leopard-right-panel-container").css("background-size",
                    //                     resultJSON.cc_organisation_applogo_width + " " +
                    //                     resultJSON.cc_organisation_applogo_height);
                    //             }
                    //         }
                    //     }
                    // }, function (error, sessionTimeout) {
                    //     if (sessionTimeout !== undefined && sessionTimeout === true) {
                    //         LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    //     } else {
                    //         LRH.ShowToast("Failed to retrieve the global config.", "error", 5000);
                    //     }
                    // }, templateList, requestListData);

                    LeopardAjaxHelper.GetControlCentreVersion(userId, function (data) {
                        if (LDH.IsObjectNull(data) || LDH.IsObjectNull(data.currentVersion)) {
                            if (LDH.IsUpdateControlCentreVersionRequired(data)) {
                                LeopardAjaxHelper.SetControlCentreVersion(orgId, null, null);
                            }
                        } else {
                            if (LDH.IsUpdateControlCentreVersionRequired(data)) {
                                LeopardAjaxHelper.SetControlCentreVersion(orgId, null, null);
                            }
                        }
                    }, function (error, sessionTimeout) {
                        if (sessionTimeout !== undefined && sessionTimeout === true) {
                            LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                        } else {
                            if (LDH.IsUpdateControlCentreVersionRequired({currentVersion: "1.0.0.0"})) {
                                LeopardAjaxHelper.SetControlCentreVersion(orgId, null, null);
                            }
                        }
                    });
                }, function (error, sessionTimeout) {
                    if (sessionTimeout !== undefined && sessionTimeout === true) {
                        LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    } else {
                        LRH.ShowToast("Failed to retrieve the user profile.", "error", 5000);
                    }
                }, null, "config", null);

                let roleUrl = window.ODataAPIGatewayUrl + window.APIGatewayRoleUrl;
                window.userRolesLookup = [];
                LeopardAjaxHelper.GenericHttpRequest("get", roleUrl, null, function (response) {
                    for (let v = 0; v < response.value.length; v++) {
                        window.userRolesLookup.push({
                            value: response.value[v].RoleCode,
                            text: response.value[v].Name,
                        });
                    }
                }, null);
            }, function () {
                that.setState({authState: that.props.authState});
            });
            that.getCurrentUserCredentials(function () {
                if (LeopardStaticUIConfig.WebsocketCommunicationEnabled) {
                    LeopardWebsocketHelper.OpenConnection();
                    window.pendingWebsocketEventKeys = [];
                }
            });

            setInterval(function () {
                that.getCurrentUserCredentials(null);
            }, 600000);
        } else {
            that.setState({authState: that.props.authState});
        }
    };

    getUserAttributes = (userProfile, retryIndex) => {
        let that = this;
        let attributeUrl = "/reports/view/user_attribute?$filter=id eq '" + userProfile.ID + "'&$orderby=name&$top=1&$count=false";
        LeopardAjaxHelper.SimpleHttpGetRequest(attributeUrl, function (attrData) {
            if (LDH.IsObjectNull(attrData.value) || typeof attrData.value !== "object") {
                if (retryIndex < 5) {
                    that.getUserAttributes(userProfile, retryIndex + 1);
                } else {
                    LRH.ShowToast("Failed to retrieve user attributes. Refresh your browser and try again.", "error", 60000);
                }
                return;
            }
            userProfile["UserAttributes"] = attrData.value;
            window.userProfile = userProfile;
        }, function (error, sessionTimeout) {
            if (retryIndex < 5) {
                that.getUserAttributes(userProfile, retryIndex + 1);
            } else {
                LRH.ShowToast("Failed to retrieve user attributes. Refresh your browser and try again.", "error", 60000);
            }
        })
    };

    getCurrentUserCredentials = (callback) => {
        LeopardSecurity.GetCurrentUserCredentials(function (credential) {
            if (!LDH.IsObjectNull(credential.name) &&
                credential.name === "NotAuthorizedException") {
                LeopardSecurity.UserLogout();
                return;
            }
            window.userCredential = credential;
            if (!LDH.IsObjectNull(callback)) callback();
        }, function (ex) {
            LeopardSecurity.UserLogout();
            console.log(ex);
        });
    };

    navHideButtonOnClick = () => {
        let that = this;

        if (that.showLeftMenuContent === false) {
            that.showLeftMenuContent = true;
            $(".leopard-left-panel-container").show(0, function () {
                that.updateWindowDimensionsOnResizing();
                LRH.TriggerWindowResizeEvent();

                setTimeout(function () {
                    that.resizeDashboardItems();
                }, 10);
            });
            $("#RootWindowContainer").removeClass("left-panel-hidden")
                .addClass("left-panel-shown");
        } else {
            that.showLeftMenuContent = false;
            $(".leopard-left-panel-container").hide(0, function () {
                that.updateWindowDimensionsOnResizing();
                LRH.TriggerWindowResizeEvent();

                setTimeout(function () {
                    that.resizeDashboardItems();
                }, 10);
            });
            $("#RootWindowContainer").removeClass("left-panel-shown")
                .addClass("left-panel-hidden");
        }
    };

    resizeDashboardItems = () => {
        let listeners = LeopardStaticUIConfig.Global_DashboardDataViewListeners;
        setTimeout(function () {
            for (let i = 0; i < listeners.length; i++) {
                if (LDH.IsObjectNull(listeners[i].instance) || listeners[i].instance === "blank") {
                    continue;
                }
                if (listeners[i].instance.NAME !== "dxTileView") {
                    if (LDH.IsObjectNull(listeners[i].instance.option)) {
                        continue;
                    }
                    let size = listeners[i].instance.option("size");
                    listeners[i].instance.option("size", size);
                    if (listeners[i].instance.NAME === "dxDataGrid") {
                        if (listeners[i].instance.getVisibleRows().length > 0) {
                            listeners[i].instance.repaint();
                        }
                    }
                }
            }

            if (LDH.IsObjectNull(window.leopardMapInstances)) {
                window.leopardMapInstances = [];
            }
            for (let i = 0; i < window.leopardMapInstances.length; i++) {
                if (LDH.IsObjectNull(window.leopardMapInstances[i])) {
                    continue;
                }
                window.leopardMapInstances[i].instance.updateSize();
            }
        }, 10);
    };

    refreshBrowserTab = () => {
        window.location.reload();
    };

    render() {
        if (this.state.masterPageOnLoadComplete === true) {
            return (
                <React.Fragment>
                    <div className="App">
                        <div className={"dashboard-settings-version"} settingsversion=""></div>
                        <div className={"dataview-settings-version"} settingsversion=""></div>
                        <div className="leopard-static-toast toast" role="alert" aria-live="assertive"
                             aria-atomic="true">
                            <div className="toast-header">
                                <strong className="me-auto toast-title"></strong>
                                <button type="button" className="btn-close" data-bs-dismiss="toast"
                                        aria-label="Close"></button>
                            </div>
                            <div className="toast-body toast-description"></div>
                        </div>
                        <div className={"leopard-add-autocomplete-blocker"}>
                            <TextBox mode={"text"} style={{height: "0px", width: "0px", border: "none"}}></TextBox>
                            <TextBox mode={"password"} style={{height: "0px", width: "0px", border: "none"}}></TextBox>
                        </div>
                        <div className={"leopard-application-loading-cover"}>
                             <span className={"leopard-loading-icon leopard-centre-box"}>
                                 <i className="fas fa-spinner fa-pulse"
                                    style={{color: "#FF8100", fontSize: "45px"}}></i>
                             </span>
                        </div>
                        <div className={"cc-update-notice-text"}>
                            <h3 style={{marginBottom: "30px"}}>Your Control Centre is outdated</h3>
                            <div>We have just released a version of the Control Centre.</div>
                            <div style={{marginBottom: "30px"}}>
                                Please refresh your browser to update the local cache.
                            </div>
                            <Button className="leopard-button refresh-browser" text={'Refresh Browser'}
                                    onClick={(e) => this.refreshBrowserTab(e)}/>
                        </div>
                        <LeopardTopMenuBar loggedInUserName={this.state.loggedInUserName}
                                           leftMenuExpandOnSelection={() => this.leftMenuExpandOnSelection(this)}
                                           leftMenuExpandAll={() => this.leftMenuExpandAll(this)}
                                           navHideButtonOnClick={this.navHideButtonOnClick}>
                        </LeopardTopMenuBar>
                        <div className="leopard-master-leftmenu-container">
                            <LeopardMasterLeftMenu
                                setLeftMenuTreeViewInstance={(e) => this.setLeftMenuTreeViewInstance(e)}
                                updateWindowDimensionsRequired={(e) => this.updateWindowDimensionsOnResizing(e)}>
                            </LeopardMasterLeftMenu>
                            <div className={"leopard-right-panel-container"}>
                                <LeopardMasterContentPanel
                                    setGridViewInstance={(e) => this.setGridViewInstance(e)}
                                    setChartInstance={(e) => this.setChartInstance(e)}
                                    setTimelineInstance={(e) => this.setTimelineInstance(e)}
                                    setPhotoInstance={(e) => this.setPhotoInstance(e)}
                                    setNotepadInstance={(e) => this.setNotepadInstance(e)}
                                    setDocumentEditorInstance={(e) => this.setDocumentEditorInstance(e)}
                                    windowHeight={0}
                                    setMapInstance={(e) => this.setMapInstance(e)}
                                    updateWindowDimensionsRequired={(e) => this.updateWindowDimensionsOnResizing(e)}>
                                </LeopardMasterContentPanel>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        } else {
            return null;
        }
    }
}

const RetrieveDataFromReducer = (state) => {
    return {state};
};

const SendDataToReducer = (dispatch) => {
    return {
        SetUserPermissions: (data) => {
            dispatch(SetUserPermissions(data));
        },
        SetLeopardAdminMode: (data) => {
            dispatch(SetLeopardAdminMode(data));
        },
        SetAuthenticatedUser: (data) => {
            dispatch(SetAuthenticatedUser(data));
        },
        SetWebAppInitialized: (data) => {
            dispatch(SetWebAppInitialized(data));
        }
    };
};

export default connect(RetrieveDataFromReducer, SendDataToReducer)(App);
