import React, {Component} from 'react';
import LeopardSingleDataView from '../pages/LeopardSingleDataView';
import LeopardTemplateView from '../pages/LeopardTemplateView';
import {connect} from 'react-redux';

class LeopardMasterContentPanel extends Component {
    initializeSingleDataViewPanel(dataSourceUrl) {
        return (
            <LeopardSingleDataView
                windowHeight={this.props.windowHeight}
                setGridViewInstance={this.props.setGridViewInstance}
                setChartInstance={this.props.setChartInstance}
                setPhotoInstance={this.props.setPhotoInstance}
                setTimelineInstance={this.props.setTimelineInstance}
                dataSourceUrl={dataSourceUrl}
                menuItemName={this.props.state.selectedLeftMenuItem.menuItemName}
                menuItemId={this.props.state.selectedLeftMenuItem.menuItemId}
                dataViewId={this.props.state.selectedLeftMenuItem.dataViewId}
                dataViewNote={this.props.state.selectedLeftMenuItem.dataViewNote}
                dataViewType={this.props.state.selectedLeftMenuItem.dataViewType}
                updateWindowDimensionsRequired={this.props.updateWindowDimensionsRequired}
                parentMenuItemName={this.props.state.selectedLeftMenuItem.parentMenuItemName}>
            </LeopardSingleDataView>
        );
    };

    initializeDashboardViewPanel() {
        return (
            <LeopardTemplateView
                windowHeight={this.props.windowHeight}
                menuItemName={this.props.state.selectedLeftMenuItem.menuItemName}
                menuItemId={this.props.state.selectedLeftMenuItem.menuItemId}
                menuItem={this.props.state.selectedLeftMenuItem}
                setChartInstance={this.props.setChartInstance}
                setPhotoInstance={this.props.setPhotoInstance}
                setTimelineInstance={this.props.setTimelineInstance}
                setNotepadInstance={this.props.setNotepadInstance}
                setDocumentEditorInstance={this.props.setDocumentEditorInstance}
                setGridViewInstance={this.props.setGridViewInstance}
                updateWindowDimensionsRequired={this.props.updateWindowDimensionsRequired}
                parentMenuItemName={this.props.state.selectedLeftMenuItem.parentMenuItemName}>
            </LeopardTemplateView>
        );
    };

    render() {
        if (this.props.state.selectedLeftMenuItem !== undefined &&
            this.props.state.selectedLeftMenuItem !== null &&
            this.props.state.selectedLeftMenuItem.contentPageType === "single-data-view") {
            let dataSourceUrl = "?tableName=" + this.props.state.selectedLeftMenuItem.dataTableName;
            return (
                <React.Fragment>
                    {this.initializeSingleDataViewPanel(dataSourceUrl)}
                </React.Fragment>
            );
        } else if (this.props.state.selectedLeftMenuItem !== undefined &&
            this.props.state.selectedLeftMenuItem !== null &&
            this.props.state.selectedLeftMenuItem.contentPageType === "dashboard-view") {
            return (
                <React.Fragment>
                    {this.initializeDashboardViewPanel()}
                </React.Fragment>
            );
        }
        return null;
    }
}

const RetrieveDataFromReducer = (state) => {
    return {state};
};

export default connect(RetrieveDataFromReducer)(LeopardMasterContentPanel);
